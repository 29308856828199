import {BaseController} from 'app/controllers/BaseController';
import {action, makeObservable, observable} from 'mobx';
import {UserStore} from 'app/store/UserStore';
import {RestApiService} from 'app/services/RestApiService';
import {IncubatorShiftStore} from 'app/store/IncubatorShiftStore';
import I18n from 'app/i18n';
import {betMoney} from 'app/utils';
import {promiseToast} from 'app/components/toasts/promiseToast';
import {chatStore} from 'app/store/global';
import {LANGUAGES} from 'app/utils/Consts';

export class IncubatorShiftController extends BaseController {
    userStore: UserStore;

    incubatorShiftStore: IncubatorShiftStore;

    restApiService = new RestApiService('/incubator/shifts');

    @observable shiftPledge: string = null;

    @observable disabled: boolean = false;

    constructor(userStore: UserStore, incubatorShiftStore: IncubatorShiftStore) {
        super();
        makeObservable(this);
        this.userStore = userStore;
        this.incubatorShiftStore = incubatorShiftStore;
    }

    @action
    onChangeShiftPledge(pledge: string): void {
        this.shiftPledge = pledge;
    }

    startShift = (): void => {
        const params = {
            shift: {
                pledge: this.shiftPledge
            }
        };
        promiseToast(this.restApiService.create(params),
            I18n.t('topped_up_pledge', {amount: betMoney({amount: this.shiftPledge})}))
            .then(response => {
                if (response.ok) {
                    if (!chatStore.langs.find(el => el === LANGUAGES.EN_INCUBATOR)) {
                        chatStore.setActiveLanguages(chatStore.langs.concat(LANGUAGES.EN_INCUBATOR));
                    }
                }
            });
        this.onChangeShiftPledge('0');
    };

    closeShift = (): void => {
        promiseToast(this.restApiService.postAction('close'), I18n.t('close_shift'))
            .then(response => {
                if (response.ok) {
                    chatStore.setActiveLanguages(chatStore.langs.filter(l => l !== LANGUAGES.EN_INCUBATOR));
                    chatStore.setCurrentLanguage(chatStore.langs[0]);
                }
            });
    };
}
