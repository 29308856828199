import React, {Fragment} from 'react';
import ArticleAside from './ArticleAside';
import {i18n_t} from 'app/i18n';

interface PropsInterface {
    hideArticles: boolean
}

export const Articles = ({hideArticles}: PropsInterface): React.ReactElement => <Fragment>
    {!hideArticles && <ArticleAside type="news" title={i18n_t('news_title')}/>}
    {!hideArticles && <ArticleAside type="promotions" title={i18n_t('promotions_title')}/>}
</Fragment>;
