import React from 'react';
import {Link} from 'react-router-dom';
import {i18n_t} from 'app/i18n';
import {getLinkPath} from 'app/utils';
import SiteVersion from '../SiteVersion';
import {getTableLinkPath} from 'app/utils/link_helper';

class Sitemap extends React.PureComponent {
    render = () => <div className="main-block">
        <div className="wrap wrap--content">
            <div className="content">
                <div className="title">{i18n_t('menu_links.sitemap')}</div>
                <div className="content__holder">
                    <div className="sitemap">
                        <div className="sitemap__groups">
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">EGB.com</div>

                                <Link to={getLinkPath('/')} className="sitemap__link">
                                    {i18n_t('menu_links.home')}
                                </Link>
                                <Link to={getLinkPath('/about')} className="sitemap__link">
                                    {i18n_t('menu_links.about')}
                                </Link>
                                <Link to={getLinkPath('/faq')} className="sitemap__link">
                                    {i18n_t('menu_links.faq')}
                                </Link>
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">{i18n_t('play')}</div>
                                <Link to={getTableLinkPath('/play/bets')} className="sitemap__link">
                                    {i18n_t('user_menu_table')}
                                </Link>
                                <Link to={getLinkPath('/play/express')} className="sitemap__link">
                                    {i18n_t('user_menu_express')}
                                </Link>
                                <Link to={getLinkPath('/play/live')} className="sitemap__link">
                                    {i18n_t('user_menu_live')}
                                </Link>
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">
                                    {i18n_t('home_play_favourite_game')}
                                </div>
                                {window.SITE_DATA.INDEX_GAMES.map((game, i) => game.name && <div key={i}>
                                    <Link
                                        to={getLinkPath(`/play/bets/${game.slug}`)}
                                        className="sitemap__link"
                                    >
                                        {i18n_t('bet_on', {game: game.name})}
                                    </Link>
                                </div>)}
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">{i18n_t('articles_title')}</div>

                                <Link to={getLinkPath('/news')} className="sitemap__link">
                                    {i18n_t('news_title')}
                                </Link>
                                <Link to={getLinkPath('/promotions')} className="sitemap__link">
                                    {i18n_t('promotions_title')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

SiteVersion.Sitemap = Sitemap;
