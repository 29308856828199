import React, {Component} from 'react';
import {casinoGamesStore} from 'app/store/global';
import CasinoGameItem from './CasinoGameItem';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import GroupContainer from './GroupContainer';
import {CasinoGamesStore} from 'app/store/CasinoGamesStore';
import {isDesktop} from 'app/utils';
import CasinoWins from 'site_version/com/CasinoWins';

interface MainPageFiltersProps {
    activeClickGame: number
    setActiveItem: (id: number) => void
    deactivateGame: () => void
    casinoGamesStore?: CasinoGamesStore
}

@inject('casinoGamesStore')
@observer
export default class MainPageFilters extends Component<MainPageFiltersProps> {
    render(): React.ReactNode {
        const {casinoFilters} = casinoGamesStore;
        return casinoGamesStore.showFilterGroups() && <>
            {casinoFilters && casinoFilters.length > 0 &&
            casinoFilters.map((filter, index) => <>
                <GroupContainer
                    countGames={filter.casinoGamesByFiltersCount} key={filter.id}
                    filter={filter}
                    show
                    title={filter.langs && filter.langs[I18n.locale] || filter.name}>

                    <div className="casino-list-games">
                        {filter.casinoGames && filter.casinoGames.map(casinoGame => <CasinoGameItem
                            key={`casinoGame-${filter.id}-${casinoGame.id}`}
                            filter={filter}
                            setActiveItem={this.props.setActiveItem}
                            showMobileButtons={this.props.activeClickGame === casinoGame.id}
                            deactivateGame={this.props.deactivateGame}
                            {...casinoGame}
                        />)}
                    </div>
                </GroupContainer>
                {!isDesktop() && index === 0 && <CasinoWins/>}
            </>)}
        </>;
    }
}
