import {isNestedLine} from 'app/lines/isNestedLine';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {isHandicapLine} from 'app/lines/isHandicapLine';

export const STATUS_OPENED = 0;
export const STATUS_STARTED = 1;
export const STATUS_CLOSED = 2;
export const STATUS_CANCELED = 3;
export const WINNER_CANCELED = -1;
export const STATUS_DRAW = -2;

export const BLUE = 1;
export const GREEN = 2;
export const RED = 3;
export const WHITE = 4;

export const GAMER_1 = 1;
export const GAMER_2 = 2;
export const DRAW = -2;

export const lineScores = line => `[${line.gamer_1_score}:${line.gamer_2_score}]`;

export const isLinesBet = (line, userBet) => userBet.line_id === line.id;

export const isFinished = status => status === STATUS_CLOSED || status === STATUS_CANCELED;

export const lineCoef = (line, num) => {
    const coef = line[`coef_${num}`];

    if (!coef || coef === '' || coef === 0) {
        return false;
    }
    return coef;
};

export const lineLimit = (line, play_money, num) => line[`limit_${play_money ? 'play' : 'real'}_money_gamer_${num}`];

export const lineGamer = (line, num, parentLine) => {
    if (isAdvantageLine(line)) {
        return parentLine[`gamer_${line.gamer}`];
    }
    return line[`gamer_${num}`];
};

export const lineAdditionalText = (line, num) => isNestedLine(line) && lineGamer(line, num).nick
    ? ` (${line[`gamer_${num}`].nick})`
    : '';

export const lineFullNick = (line, num, parentLine) => {
    if (isAdvantageLine(line)) {
        const gamerKey = isNestedLine(parentLine) ? `parent_gamer_${line.gamer}` : `gamer_${line.gamer}`;
        const gamerNick = parentLine[gamerKey].nick;
        return line[`score_text_${num}`].replace('%s', gamerNick) + lineAdditionalText(parentLine, line.gamer);
    }

    return isHandicapLine(line) ? lineFullNickHandicap(line, num) : lineFullNickMainOrNested(line, num);
};

const lineFullNickHandicap = (line, num) => {
    const gamer = line[`gamer_${num || GAMER_1}`];
    return gamer.game_name
        ? `${gamer.game_name} ${gamer.nick}`
        : gamer.nick;
};

const lineFullNickMainOrNested = (line, num) => {
    const gamer = line[`gamer_${num}`];
    return gamer.type
        ? `${gamer.game_name ? `${gamer.game_name} ` : ''}${gamer.nick}`
        : gamer.nick;
};

export const lineNickWithParent = (line, parentLine, num) => {
    if (isAdvantageLine(line) || isHandicapLine(line)) {
        return lineFullNick(line, num, parentLine);
    }

    const gamer = lineGamer(line, num);

    if (gamer.type) {
        return lineFullNick(line, num);
    }

    if (line.game_id === 0) {
        return gamer.nick;
    }

    const parentGamer = lineGamer(parentLine, num);
    return `${gamer.nick}(${parentGamer.nick}) `;
};

export const isLineGamerWinner = (line, num) => line.winner === num;

export const isWinnerClass = (line, gamerNum) => isLineGamerWinner(line, gamerNum) ? ' table-bets__winner' : '';

export const isLoserClass = (line, num) => line.status === STATUS_CANCELED && isWhiteLine(line.color) ||
    !isLineGamerWinner(line, num) && line.status === STATUS_CLOSED && isWhiteLine(line.color)
    ? ' result-lost'
    : '';

export const lineFormResultTitle = (line, parentLine, num) => {
    if (isAdvantageLine(line) || isHandicapLine(line)) {
        return lineFullNick(line, num, parentLine);
    }

    return `${lineNickWithParent(line, parentLine, GAMER_1)} VS ${lineNickWithParent(line, parentLine, GAMER_2)}`;
};

export const lineId = line => line.hash_id || line.id;

export const lineEventName = (line, isNested) => {
    if (line.type === 'Handicap') {
        const gamer = lineGamer(line, 1);
        return gamer.game_name;
    }

    if (!isNested) {
        return line.tourn;
    }

    const gamer = lineGamer(line, 1);

    if (!gamer.game_name) {
        return null;
    }

    return gamer.type ? gamer.game_name : gamer.nick;
};

export const isLineCancelled = line => line.winner === WINNER_CANCELED;

export const isOpened = color => color === BLUE;

export const hasOpenedNested = color => color === GREEN;

export const isRedLine = color => color === RED;

export const isWhiteLine = color => color === WHITE;

export const hasWinner = line => line.winner > 0;

export const isCancelled = line => line.status === STATUS_CANCELED;

export const lineDate = line => line.date;
