import SiteVersion from '../SiteVersion';
import {getPromoUrl} from 'app/utils/getPromoUrl';

export const HeaderMenu = [
    {
        path: '/play/bets',
        section: 'bets',
        svg: 'tap_bar_play'
    },
    {
        path: '/casino',
        section: 'casino',
        svg: 'casino'
    },
    {
        path: '/shop/money',
        section: 'shop',
        svg: 'shop'
    },
    {
        path: '/news',
        section: 'news',
        svg: 'news'
    },
    {
        path: getPromoUrl(),
        section: 'promo',
        svg: 'promo_action'
    },
    {
        path: '/faq',
        section: 'faq',
        svg: 'faq'
    }
];

SiteVersion.HeaderMenu = HeaderMenu;
