import React, {Component, MouseEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import AsideItem from 'site_version/com/AsideItem';
import {isGuest} from 'app/utils';
import {GiveawayStore} from 'app/store/GiveawayStore';
import Prize from 'app/components/my_profile/artifacts/popup/prize';
import {openRegistration} from 'app/components/auth/auth';
import Timer from 'app/components/reusable/Timer';
import {isoToUnix} from 'app/utils/time/IsoToUnix';
import AccordionItem from 'app/components/giveaway/AccordionItem';
import {prizeName} from 'app/components/my_profile/artifacts/popup/PrizeName';
import ButtonBellWithMessage from 'app/components/reusable/ButtonBellWithMessage';
import {NotificationSettingsStore} from 'app/store/NotificationSettingsStore';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';

interface AsideGiveawayProps {
    giveawayStore?: GiveawayStore
    itemClassName: string
    notificationSettingsStore?: NotificationSettingsStore
}

@inject('giveawayStore', 'notificationSettingsStore')
@observer
export default class Giveaway extends Component<AsideGiveawayProps> {
    state = {
        disabled: false,
        showWinners: false
    };

    header = (): ReactNode => {
        const {giveaway: {finished_at, participants_count}} = this.props.giveawayStore.giveaway;
        const {notificationSettings} = this.props.notificationSettingsStore;
        const giveaway_notification = notificationSettings.giveaway;
        return <div className="aside-item-heading__title">
            <h3>{I18n.t('giveaway')}</h3>
            <div className="number-participants">
                <ButtonBellWithMessage
                    isActive={giveaway_notification}
                    message={I18n.t('giveaway_subscribe_message')}
                    handleOnClick={this.subscribeNotification}
                />
                <span className="timer"><Timer endTime={isoToUnix(finished_at)} onlyMinutes/></span>
                <span className="participants">
                    ({I18n.t('participants_count', {count: participants_count})})
                </span>
            </div>
        </div>;
    };

    takePart = (e: MouseEvent): void => {
        if (this.state.disabled) {
            return;
        }
        e.preventDefault();
        if (isGuest()) {
            openRegistration('GIVEAWAY_TAKE_PART');
        } else {
            this.props.giveawayStore.takePart().finally(() => this.setState({disabled: false}));
        }
    };

    showHideWinners = (): void => this.setState({showWinners: !this.state.showWinners});

    subscribeNotification = (): void => {
        const {notificationSettings} = this.props.notificationSettingsStore;
        this.props.notificationSettingsStore
            .saveSettings({giveaway: !notificationSettings.giveaway})
            .then(data => {
                if (data) {
                    if (notificationSettings.giveaway) {
                        toastSuccess(I18n.t('giveaway_subscribe_message'));
                    }
                } else {
                    toastError(I18n.t('server_error_try_later'));
                }
            });
    };

    render(): ReactNode {
        const {giveaway, participated, previous_winners} = this.props.giveawayStore.giveaway;
        const {showWinners} = this.state;

        if (!giveaway) {
            return null;
        }

        const {prize} = giveaway;
        return <AsideItem header={this.header()} name="giveaway" itemClassName={this.props.itemClassName}>
            <div className="giveaway-content">
                <div className="giveaway-prize">
                    <div className="giveaway-prize__image">
                        <Prize loot={prize}/>
                    </div>
                    <div className="giveaway-prize__description">
                        <span className="name">{prizeName(prize)}</span>
                    </div>
                </div>
                <button
                    className={`button-adaptive${participated ? ' button-adaptive_disable' : ''}`}
                    disabled={participated}
                    onClick={this.takePart}
                >
                    {I18n.t('prizes_texts.take_part')}
                </button>
            </div>
            {previous_winners.length > 0 && <div className="accordion-content">
                <div className="accordion-header">
                    <button className={`button-accordion${showWinners ? ' active' : ''}`}
                        onClick={this.showHideWinners}>
                        <span className="icon">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-arr-select-small"/>
                            </svg>
                        </span>
                        <span className="name">{I18n.t('last_winners')}</span>
                        <span className="icon">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-arr-select-small"/>
                            </svg>
                        </span>
                    </button>
                </div>
                {showWinners && <div className="accordion-body">
                    <div className="accordion-list">
                        {previous_winners.map((w, i) => <AccordionItem key={i} {...w}/>)}
                    </div>
                </div>}
            </div>}
        </AsideItem>;
    }
}
