import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import Pagination from './pagination';
import {betMoney, getLinkPath, isGuest, scrollToElement} from 'app/utils';
import $ from 'jquery';
import {orderStore, userStore} from 'app/store/global';
import Order from 'app/components/shop/marketplace/order';
import {showPopup} from 'app/utils/modals/ModalsService';
import PlaceByOrderArtifactPopup from './place_by_order_artifact_popup';
import {openAuth} from 'app/components/auth/auth';
import {translatedName} from 'app/utils/translations_helper';
import SimpleLineChart from 'app/components/my_profile/artifacts/simple_line_chart';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import Smiles from 'app/components/my_profile/artifacts/popup/smiles';
import LootboxItem from 'app/components/shop/lootboxes/lootbox_item';
import InventoryItemDescription from 'app/components/my_profile/artifacts/popup/inventory_item_description';
import {locationQuery} from 'app/utils/navigate/location';
import withRouter from 'app/utils/navigate/customWithRouter';

class Orders extends Component {
    state = {loading: true};

    rootElement = React.createRef();

    constructor(props) {
        super(props);
        this.switchPage = this.switchPage.bind(this);
    }

    componentDidMount() {
        this.switchPage({page: locationQuery().page || 1});
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.switchPage({page: locationQuery().page || 1});
        }
    }

    switchPage(params) {
        $.ajax(getLinkPath(`/shop/marketplace/${this.props.params.name}/orders`), {
            beforeSend: () => this.setLoadingTo(true),
            data: params,
            dataType: 'json',
            success: response => {
                orderStore.updateOrders(response);
                this.setLoadingTo(false, () => scrollToElement($(this.rootElement.current)));
            }
        });
    }

    setLoadingTo = (state, callback) => this.setState({loading: state}, callback);

    placeByOrderPopup = () => {
        if (userStore.user.restrict_artifact) {
            return;
        }

        if (isGuest()) {
            openAuth(`/shop/marketplace/${this.props.params.name}/orders`);
            return;
        }

        const {recipe, requested_artifacts_data = {}} = this.props.orderStore;
        showPopup(
            'sell-inventory',
            props => <PlaceByOrderArtifactPopup
                popupId={props.popupId}
                recipe={recipe}
                max_price={requested_artifacts_data.max_price}/>,
            ['inventory-popup']
        );
    };

    render() {
        const {
            orders: items = [],
            recipe = {
                description: '',
                description_langs: {},
                langs: {},
                name: ''
            },
            paginate,
            requested_artifacts_data = {}
        } = this.props.orderStore;
        const {item_type} = recipe;
        const {restrict_artifact} = userStore.user;
        const {loading} = this.state;

        return <div id="market-details" ref={this.rootElement} className="tabs__content-item">
            <div className="market-details">
                <div className="market-details--info">
                    <div className="market-details--info--content">
                        <div className="market-details--info--description">
                            <div className="market-details--info--img-wrap">
                                <div className="market-details--top-mobile">
                                    <h1 className="market-details--info--header-mobile">{translatedName(recipe)}</h1>
                                </div>
                                <div className="market-details--info--img">
                                    <div className="market-details--info--inner--img">
                                        <SimpleArtifactImage artifact={recipe}/>
                                    </div>
                                </div>
                            </div>
                            <div className="market-details--info--desc">
                                <h1 className="market-details--info--desc-name">{translatedName(recipe)}</h1>
                                <div className="market-details--info--desc-title gray-title">
                                    {I18n.t('overview')}
                                </div>
                                <div className="market-details--info--desc-text">
                                    {item_type === 'lootbox'
                                        ? <LootboxItem {...recipe}/>
                                        : <InventoryItemDescription
                                            {...recipe}
                                            isLootbox={false}
                                        />}
                                </div>
                                <div className="market-details--info--slots">
                                    <Smiles {...recipe}/>
                                </div>
                            </div>
                        </div>
                        <div className="market-details--info--prices">
                            <div className="market-details--info--prices-title">
                                {I18n.t('median_sale_price')}
                            </div>
                            <div className="market-details--info--prices-diagram">
                                <div id="inventory-sales">
                                    <SimpleLineChart
                                        recipe={{
                                            id: this.props.params.name,
                                            ...recipe
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="market-details--info--footer">
                        <div className="market-details__requests">
                            {requested_artifacts_data.count > 0 && <Fragment>
                                <div
                                    className="market-details__requests--count">{requested_artifacts_data.count}</div>
                                <div className="market-details__requests--goal">
                                    <span>{I18n.t('requests_to_buy_at')}</span></div>
                                <div className="market-details__requests--price">
                                    {betMoney({amount: requested_artifacts_data.max_price})}
                                    <span>{I18n.t('or_lower')}</span>
                                </div>
                            </Fragment>}
                        </div>
                        <div className="market-details--info--footer-actions">
                            <button
                                disabled={loading}
                                className={`btn${restrict_artifact ? ' btn--disable' : ''}`}
                                onClick={this.placeByOrderPopup}>
                                {I18n.t('place_buy_order')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="market-details__items js-table">
                    <div className="market-details__items__heading tables-heading">
                        <div className="market-details__items__name-seller">
                            <div className="market-details__items__name">{I18n.t('name')}</div>
                            <div className="market-details__items__seller">{I18n.t('seller')}</div>
                        </div>
                        <div className="market-details__items__price-actions">
                            <div className="market-details__items__price">{I18n.t('price')}</div>
                            <div className="market-details__items__actions"/>
                        </div>
                    </div>
                    {loading
                        ? <div className="market-details__item">
                            <div className="loader"/>
                        </div>
                        : items.map(order => <Order
                            key={order.id}
                            recipe={recipe}
                            restrict_artifact={restrict_artifact}
                            query={{
                                marketplace_id: this.props.params.name,
                                page: locationQuery().page
                            }}
                            {...order}
                        />)}
                </div>
                <Pagination {...paginate} switchPage={this.switchPage}/>
            </div>
        </div>;
    }
}

Orders.propTypes = {
    orderStore: PropTypes.shape({
        orders: PropTypes.arrayOf(PropTypes.object),
        paginate: PropTypes.shape({
            current_page: PropTypes.number,
            per_page: PropTypes.number,
            total_entries: PropTypes.number,
            total_pages: PropTypes.number
        }),
        recipe: PropTypes.object,
        requested_artifacts_data: PropTypes.shape({
            count: PropTypes.number,
            max_price: PropTypes.number.isRequired
        })
    })
};

export default withRouter(inject('orderStore')(observer(Orders)));
