import React from 'react';
import SiteVersion from '../SiteVersion';
import {LOGO_IMAGE, LOGO_IMAGE_N} from 'app/consts';
import LogoLink from 'site_version/common/LogoLink';

SiteVersion.logoLink = (onClick?: () => void, redirectToTable?: boolean) => <LogoLink
    logo_image={window.settings.config.new_year_theme ? LOGO_IMAGE_N : LOGO_IMAGE}
    redirectToTable={redirectToTable}
    alt="EGB.com"
    title="EGB.COM"
    onClick={onClick}
/>;
