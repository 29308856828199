import SiteVersion from '../SiteVersion';

SiteVersion.HeaderMenu = [
    {
        path: '/play/bets',
        section: 'bets'
    },
    {
        path: '/casino',
        section: 'casino',
        svg: 'casino'
    },
    {
        path: '/faq',
        section: 'faq'
    }
];
