import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import SiteVersion from '../SiteVersion';

interface UserBalancePointsProps {
    points: string;
}

class UserBalancePoints extends PureComponent<UserBalancePointsProps> {
    render(): ReactNode {
        return <div className="balance-status__item">
            <div className="balance-status__item-label balance-units-label">{I18n.t('user_points')}:</div>
            <div className="balance-status__item-value balance-units__value">
                {this.props.points}
            </div>
        </div>;
    }
}

SiteVersion.userBalancePoints = props => <UserBalancePoints {...props}/>;
