import React, {PureComponent, ReactNode} from 'react';
import SiteVersion from 'site_version/SiteVersion';

export default class IncubatorVolumeButton extends PureComponent {
    render(): ReactNode {
        return <div className="button-incubator-volume">
            {SiteVersion.volumeControl()}
        </div>;
    }
}
