import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {goToRoute, isDesktop} from 'app/utils';
import {LANGUAGES} from 'app/utils/Consts';
import {chatStore} from 'app/store/global';

export default class IncubatorChatButton extends PureComponent {
    onClickChat = (): void => {
        chatStore.setCurrentLanguage(LANGUAGES.EN_INCUBATOR);
        if (!isDesktop()) {
            goToRoute('/chat/mobile');
        }
    };

    render(): ReactNode {
        return <>
            {isDesktop()
                ? <button
                    className={'button-adaptive button-adaptive_full-thin'}
                    onClick={this.onClickChat}
                >
                    {I18n.t('chat_title')}
                </button>
                : <button
                    className="button-incubator-chat"
                    onClick={this.onClickChat}>
                    <svg className="svg-default">
                        <use xlinkHref="#svg-icon-chat-full"/>
                    </svg>
                </button>
            }
        </>;
    }
}
