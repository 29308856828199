import LineInterface from 'app/interfaces/LineInterface';
import {lineGamer} from 'app/lines/lineGamer';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';

export const tableNick = (line: LineInterface, gamerNum: number): string => {
    if (isAdvantageLine(line)) {
        return line[`gamer_short_text_${gamerNum}`];
    }

    return lineGamer(line, gamerNum).nick;
};
