import {ELEMENT_NOT_FOUND, LANGUAGES} from 'app/utils/Consts';

const CYRILLIC_LANGS = [
    'am',
    'az',
    'by',
    'ge',
    'kg',
    'md',
    'ru',
    'tm',
    'tj',
    'ua',
    'uz'
];

export const defaultChatLang = (incubator = false): string[] => {
    const lang = [LANGUAGES.EN];

    if (incubator) {
        lang.push(LANGUAGES.EN_INCUBATOR);
    }

    if (window.CURRENT_LOCALE === 'ru' ||
        CYRILLIC_LANGS.indexOf(window.settings.user.country_code) !== ELEMENT_NOT_FOUND) {
        lang.unshift('ru');
    }
    return lang;
};
