import SiteVersion from '../SiteVersion';

SiteVersion.HeaderMobileMenu = [
    {
        path: '/contacts',
        section: 'contacts',
        svg: 'contact'
    }, {
        path: '/faq',
        section: 'faq',
        svg: 'faq'
    }
];
