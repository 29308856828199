import React, {ReactNode} from 'react';
import PokerPanelButtonFold from 'app/components/poker/poker_controller_panel/PokerPanelButtonFold';
import PokerPanelButtonCheckCall from 'app/components/poker/poker_controller_panel/PokerPanelButtonCheckCall';
import PokerPanelButtonBetRaise from 'app/components/poker/poker_controller_panel/PokerPanelButtonBetRaise';
import PokerPanelButtonPokerGoBet from 'app/components/poker/poker_controller_panel/PokerPanelButtonPokerGoBet';

export default class PokerControllerPanel extends React.Component {
    render(): ReactNode {
        return <div className="poker-controller-panel">
            <PokerPanelButtonFold/>
            <PokerPanelButtonCheckCall/>
            <PokerPanelButtonBetRaise/>
            <PokerPanelButtonPokerGoBet/>
        </div>;
    }
}
