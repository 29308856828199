import {getLinkPath} from 'app/utils/link_helper';
import {NavLink} from 'react-router-dom';
import {scrollToTop} from 'app/utils';
import React, {Component, ReactNode} from 'react';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import PreviewNewList from 'app/components/pages/articles/home_page/PreviewNewList';
import PromoNewItem from 'app/components/pages/articles/PromoNewItem';
import {getTranslateByType} from 'app/utils/articles/home_page/getTranslateByType';
import {getType} from 'app/utils/articles/home_page/getType';

interface ArticleProps {
    articles: ArticleInterface[],
    type: string
}

export default class PreviewNew extends Component<ArticleProps> {
    render(): ReactNode {
        const {articles} = this.props;
        const type = getType(this.props.type);
        const title = getTranslateByType(this.props.type);
        return <>
            <div className="header-promo-articles classical">
                <h1 className="name">
                    <NavLink
                        to={getLinkPath(`/${type}`)}
                        dangerouslySetInnerHTML={{__html: title}}
                        onClick={scrollToTop}
                    />
                </h1>
            </div>
            <PreviewNewList>
                {articles.map(article => <PromoNewItem key={article.id} data={article} type={type} />)}
            </PreviewNewList>
        </>;
    }
}
