import {action, makeObservable, observable} from 'mobx';
import TableInterface from 'app/interfaces/poker/TableInterface';
import Get from 'app/utils/Get';
import {
    subcribePokerTables,
    subscribePokerTable,
    unsubscribe
} from 'modules/WsSubscription';
import {userStore} from './global';

export class PokerStore {
    @observable tables: TableInterface[] = [];

    @observable currentTable: TableInterface = null;

    @observable subscription = null;

    @observable subcriptionOnTables = null;

    constructor() {
        makeObservable(this);
    }

    @action
    openTable(table_id: string): void {
        this.currentTable = this.tables.find(x => x.table_id === table_id);
    }

    @action
    setTables = (tables: TableInterface[]): void => {
        this.tables = tables;
    };

    @action
    updateCurrentTable = (table: TableInterface): void => {
        this.currentTable = table;
    };

    @action
    unsubscribeChannel = (): void => {
        this.subscription = unsubscribe(this.subscription);
    };

    @action
    subscribeChannel = (): void => {
        this.subscription = subscribePokerTable(userStore.user.id, ({data: {event, payload}}) => {
            if (event === 'update') {
                this.updateCurrentTable(payload.table);
            }
        });
    };

    listen(): void {
        this.subcriptionOnTables = subcribePokerTables(({data: {event, payload}}) => {
            if (event === 'update') {
                this.setTables(payload.tables);
            }
        });
    }

    loadTables = (): void => {
        new Get({
            url: '/poker/tables'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.setTables(response);
            });
    };
}
