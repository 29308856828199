import React, {Component, ReactNode} from 'react';
import PokerMenuButtonBalance from 'app/components/poker/poker_menu_container/PokerMenuButtonBalance';
import PokerMenuButtonDeposit from 'app/components/poker/poker_menu_container/PokerMenuButtonDeposit';
import PokerMenuButtonPause from 'app/components/poker/poker_menu_container/PokerMenuButtonPause';
import PokerMenuButtonQuit from 'app/components/poker/poker_menu_container/PokerMenuButtonQuit';
import PokerMenuButtonSound from 'app/components/poker/poker_menu_container/PokerMenuButtonSound';

export default class PokerMenuContainer extends Component {
    render(): ReactNode {
        return <div className="poker-menu-container">
            <div className="poker-menu-container__item">
                <PokerMenuButtonBalance/>
                <PokerMenuButtonDeposit/>
            </div>
            <div className="poker-menu-container__item">
                <PokerMenuButtonPause/>
            </div>
            <div className="poker-menu-container__item">
                <PokerMenuButtonQuit />
            </div>
            <div className="poker-menu-container__item">
                <PokerMenuButtonSound/>
            </div>
        </div>;
    }
}
