import React, {Component, ReactNode} from 'react';
import {NavLink} from 'react-router-dom';
import I18n from 'app/i18n';
import {closeMenuAndScrollToTop, getLinkPath} from 'app/utils';
import {countedTitle} from 'app/utils/countedTitle';
import SiteVersion from 'site_version/SiteVersion';
import {getPromoUrl} from 'app/utils/getPromoUrl';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {inject, observer} from 'mobx-react';

interface UserNavBottomProps {
    articlesStore?: ArticlesStore
}

@inject('articlesStore')
@observer
class UserNavBottom extends Component<UserNavBottomProps> {
    constructor(props: UserNavBottomProps) {
        super(props);
        this.props.articlesStore.initPromoNew();
    }

    render(): ReactNode {
        return <>
            <NavLink
                to={getLinkPath(getPromoUrl())}
                onClick={closeMenuAndScrollToTop}
                className={({isActive}) => isActive ? 'user-nav__item active-link' : 'user-nav__item'}
            >
                <span className="user-nav__icon">
                    <svg height="15px" width="20px">
                        <use xlinkHref="#svg-promo_action"/>
                    </svg>
                </span>
                <span>{countedTitle(I18n.t('promo_actions'), this.props.articlesStore.activePromoNewCount)}</span>
            </NavLink>
        </>;
    }
}

SiteVersion.userNavBottom = props => <UserNavBottom {...props}/>;
