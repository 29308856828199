import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

export default class PokerPanelButtonPokerGoBet extends React.Component {
    render(): ReactNode {
        return <div className="poker-go-bet">
            <label className="field__label" htmlFor="pokerBetInput">{I18n.t('bet_amount')}</label>
            <label className="symbol-label" htmlFor="pokerBetInput">$</label>
            <input
                className="field__input bold"
                id="pokerBetInput"
                type="text"
            />
        </div>;
    }
}
