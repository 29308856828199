import BaseValidation from 'validations/BaseValidation';

const regex = new RegExp(
    '^https:\\/\\/(?:|www\\.)' +
        '(?:twitch.tv|goodgame.ru|trovo.live|youtu.be|youtube.com|youtube.com/live|facebook.com|facebook.com/gaming|' +
        'caffeine.tv|vkplay.live|play.afreecatv.com)/\\S+?$'
    , 'u'
);

export default class StreamValidation extends BaseValidation<string, null> {
    execute = (): boolean => regex.test(this.attribute);
}
