import React, {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';
import {i18n_t} from 'app/i18n';
import {countedTitle} from 'app/utils/countedTitle';
import AlertPoint from 'app/components/AlertPoint';
import {closeMenuAndScrollToTop} from 'app/utils';
import NotificationsLink from 'site_version/common/NotificationsLink';
import BonusInterface from 'app/interfaces/BonusInterface';


interface UserNavProps {
    sections: {
        svg: string,
        icon_height: string,
        icon_width: string,
        path: string,
        translate: string,
        alert(): void | {}
    }[]
    onClose(): void
    bonuses: BonusInterface[]
    children?: React.ReactNode
}

export default class UserNav extends Component<UserNavProps> {
    render(): ReactNode {
        const {sections, children, bonuses} = this.props;
        return <div className="user-nav">
            {sections.map(section => section.translate === 'messages'
                ? <NotificationsLink key="notifications" onClose={closeMenuAndScrollToTop}/>
                : <Link
                    to={getLinkPath(section.path)}
                    onClick={closeMenuAndScrollToTop}
                    key={section.translate} className="user-nav__item"
                >
                    <span className="user-nav__icon">
                        <svg height={section.icon_height} width={section.icon_width}>
                            <use xlinkHref={`#svg-${section.svg || section.translate}`}/>
                        </svg>
                        {section.alert && section.alert() && <AlertPoint/>}
                    </span>
                    <span>
                        {i18n_t(`user.${section.translate}`)}
                        {section.translate === 'my_bonuses' && countedTitle('', bonuses.length)}
                    </span>
                </Link>)}
            {children}
        </div>;
    }
}
