import {BLUE, GREEN} from 'app/lines/line_helpers';
import Row from 'app/components/pages/home_page/LetsPlay/row';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import {TableStore} from 'app/store/TableStore';
import {getLinkPath} from 'app/utils/link_helper';
import {currentStream} from 'app/utils/currentStream';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {createSerialRendering} from 'app/lines/createSerialRendering';

interface PropsInterface {
    tableStore: TableStore
}

const MAX_LINES_COUNT = 5;
const IMPORTANT_MATCH = 0;
const FINAL_MATCH = 1;

@inject('tableStore')
@observer
export default class LetsPlay extends Component<PropsInterface> {
    filteredLines(): LineInterface[] {
        const importantLines = [];
        const liveLines = [];
        const simpleLines = [];

        this.props.tableStore.bets.slice().sort((a, b) => {
            if (a.date === b.date) {
                return a.id - b.id;
            }
            return a.date - b.date;
        }).forEach(line => {
            if ([BLUE, GREEN].includes(line.color)) {
                if (line.label_type === IMPORTANT_MATCH || line.label_type === FINAL_MATCH) {
                    importantLines.push(line);
                } else if (line.live) {
                    liveLines.push(line);
                } else {
                    simpleLines.push(line);
                }
            }
        });

        if (importantLines.length !== 0) {
            return importantLines;
        }

        if (liveLines.length >= MAX_LINES_COUNT) {
            return liveLines.slice(0, MAX_LINES_COUNT - 1);
        }

        const simpleLinesCount = MAX_LINES_COUNT - liveLines.length - 1;
        return liveLines.concat(simpleLines.slice(0, simpleLinesCount));
    }

    render(): React.ReactNode {
        return <div className="lets-go-play">
            <div className="lets-go-play__header">
                <Link to={getLinkPath('/play')}>
                    {I18n.t('home_start_playing')}
                </Link>
            </div>
            <div className="lets-go-play__content">
                <table className="lets-go-play__table">
                    <tbody>
                        {this.filteredLines().map(line => <Row
                            key={line.id}
                            line={line}
                            currentStream={currentStream(line)}
                            serial={createSerialRendering(line)}
                            updatedAt={line.ut}
                        />)}
                    </tbody>
                </table>
            </div>
            <div className="lets-go-play__button">
                <Link to={getLinkPath('/play')} className="go-bet-button">
                    {I18n.t('go_to_all_bets')}
                </Link>
            </div>
        </div>;
    }
}
