import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {isGuest} from 'app/utils';
import {isNewDesktop} from 'app/utils/windowFunctions';
import {size380x380} from 'app/utils/CasinoGameImages';
import {SignUpButton} from 'app/components/auth/SignUpButton';
import {favoriteGameChange, startDemo, startGame} from 'app/services/CasinoServices';
import {showCasinoSignUp} from 'app/utils/IsRegisterUser';
import {CasinoFilterInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';

interface CasinoGameItemProps {
    id: number,
    name: string,
    identifier: string,
    identifier2: string,
    provider_name: string,
    favorite: boolean,
    payout?: number,
    filter?: CasinoFilterInterface,
    setActiveItem: (id: number) => void,
    showMobileButtons: boolean,
    deactivateGame: () => void
    points: boolean
}

export default class CasinoGameItem extends PureComponent<CasinoGameItemProps> {
    rootElement = React.createRef<HTMLDivElement>();

    componentDidUpdate(): void {
        if (this.props.showMobileButtons) {
            document.addEventListener('click', this.handleClickOutside, true);
        } else {
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e: MouseEvent): void => {
        if (this.props.showMobileButtons && this.rootElement.current &&
            !this.rootElement.current.contains(e.target as HTMLElement)) {
            this.props.deactivateGame();
        }
    };

    favoriteGameChoice = (id: number, favorite: boolean, filterId: number): void => {
        favoriteGameChange(id, favorite, filterId);
    };

    handleChooseFavoriteGame = (): void => {
        const {id, favorite, filter} = this.props;

        if (showCasinoSignUp()) {
            return;
        }
        this.favoriteGameChoice(id, !favorite, filter && filter.id);
    };

    mobileOpenButtons = (): void => {
        if (!isNewDesktop()) {
            this.props.setActiveItem(this.props.id);
        }
    };

    handleStartDemo = (): void => {
        const {id, filter} = this.props;
        startDemo(id, filter);
    };

    handleStartGame = (): void => {
        const {id, filter} = this.props;
        startGame(id, filter);
    };

    render(): React.ReactNode {
        const {identifier, identifier2, provider_name, favorite, points, name, payout} = this.props;
        return <div ref={this.rootElement} className="casino-game-item">
            <div className="casino-game-image-container">
                <div className="casino-game-image-container__adaptive">
                    <div className="casino-game-image">
                        <img
                            alt={identifier2}
                            src={size380x380(provider_name, identifier)}/>
                    </div>
                </div>
            </div>
            <div
                className={`casino-game-name-container${this.props.showMobileButtons ? ' active' : ''}`}>
                <span className="casino-game-name">{name}</span>
                {payout && <span className="casino-game-rtp">RTP: {`${payout}%`}</span>}
                {points && <div className="casino-game-points">
                    <span className="casino-game-points__label">{`+ ${I18n.t('user.points')}`}</span>
                </div>}
            </div>
            <button
                className={`button-casino-like ${favorite ? 'active' : ''}`}
                onClick={this.handleChooseFavoriteGame}>
                <span className="button-casino-like__icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-button-favourite"/>
                    </svg>
                </span>
            </button>
            { !isNewDesktop() && <button
                className="button-show-buttons"
                onClick={this.mobileOpenButtons}>
            </button>}
            <div className={`casino-game-buttons${this.props.showMobileButtons ? ' active' : ''}`}>
                <div className="casino-game-buttons__inner">
                    { isGuest()
                        ? <SignUpButton title={I18n.t('play')} usingOnTab={'CASINO'}/>
                        : <button
                            className="button-adaptive button-adaptive_casino"
                            onClick={this.handleStartGame}>
                            {I18n.t('play')}
                        </button>
                    }
                    { isGuest()
                        ? <SignUpButton
                            usingOnTab={'CASINO'}
                            className={'button-adaptive_casino-icon'}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-button-eye"/>
                            </svg>
                            <span className="button-adaptive__name">
                                {I18n.t('demo')}
                            </span>
                        </SignUpButton>
                        : <button
                            className="button-adaptive_casino-icon"
                            onClick={this.handleStartDemo}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-button-eye"/>
                            </svg>
                            <span className="button-adaptive__name">
                                {I18n.t('demo')}
                            </span>
                        </button>}
                </div>
            </div>
        </div>;
    }
}
