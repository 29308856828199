import React, {Component, ReactNode} from 'react';
import {betMoney, isGuest, scrollToTop} from 'app/utils';
import {inject, observer} from 'mobx-react';
import {openAuth} from 'app/components/auth/auth';
import {UserStore} from 'app/store/UserStore';
import {betsBalance} from 'app/utils/balance/betsBalance';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import BetSlipMenuTabs from 'app/components/bet_slip/menu/BetSlipMenuTabs';

interface BetSlipMenuTabsProps {
    userStore?: UserStore
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('userStore', 'betSlipStore', 'controller')
@observer
export default class BetSlipMenuTabsContainer extends Component<BetSlipMenuTabsProps> {
    handleSwitchTab = (type: string): void => this.props.controller.switchTab(type);

    onClickDeposits = (): void => {
        scrollToTop();
        if (isGuest()) {
            openAuth();
        }
    };

    render(): ReactNode {
        const {userStore, betSlipStore: {loadingForm, type}} = this.props;
        const {play_money, lite} = userStore.user;
        const balance = betsBalance(userStore.user);
        const money = balance ? betMoney({amount: balance}) : '$0.00';
        return <BetSlipMenuTabs
            handleSwitchTab={this.handleSwitchTab}
            onClickDeposits={this.onClickDeposits}
            type={type}
            loadingForm={loadingForm}
            play_money={play_money}
            lite={lite}
            money={money}
        />;
    }
}
