import React, {memo, useEffect, useRef, useState} from 'react';
import Header from './header';
import SiteVersion from 'site_version/SiteVersion';
import NotificationCenter from '../informers';
import ToastList from '../toasts';
import {checkingPages, isDesktop, isGuest, updateMeta, updateTitle, mobileAndTabletCheck} from 'app/utils';
import CookiesPolicy from '../cookies_policy';
import {StreamsPlayPage} from '../streams';
import {showBetForm} from '../table';
import {TapBar} from './tap_bar';
import $ from 'jquery';
import {tableStore, streamStore} from 'app/store/global';
import SmartStoriesMobile from 'app/components/pages/home_page/SmartStories/SmartStoriesMobile';
import {isBetPage} from 'app/utils/IsBetPage';
import {isCasinoPage} from 'app/utils/IsCasinoPage';
import {isDepositOrCashoutPage} from 'app/utils/IsDepositOrCashoutPage';
import {isMainPage} from 'app/utils/isMainPage';
import BetSlipContainer from 'app/components/bet_slip/BetSlip.container';
import PokerContainer from 'app/components/poker/PokerContainer';
import Footer from 'site_version/common/Footer';
import BarLoginMobile from 'app/components/auth/BarLoginMobile';
import {useOutlet} from 'react-router';
// @ts-ignore
import {LOCALES} from 'app/consts/locales';
import {useLocation} from 'react-router-dom';

const NOT_FOUND_INDEX = -1;

const applyMetaTags = () => {
    const TWO_TAG = 2;
    const path = location.pathname;
    const tags = window.META_TAGS;
    const meta = tags.length === TWO_TAG && path.indexOf(tags[1].url) !== NOT_FOUND_INDEX ? tags[1] : tags[0];

    if (meta) {
        updateTitle(meta.title);
        updateMeta(meta);
    }
};

const isHomePage = () => {
    const {pathname} = location;

    return pathname === '/' || LOCALES.indexOf(pathname.replace(/\//gu, '')) !== NOT_FOUND_INDEX;
};

const homeOrDesktop = () => isHomePage() || isDesktop();

const isChatMobile = () => {
    const {pathname} = location;
    return pathname.search('chat/mobile') !== NOT_FOUND_INDEX;
};

// eslint-disable-next-line max-statements
const AppPageWrapper = memo(() => {
    const [hideArticles, setHideArticles] = useState<boolean>(false);
    const [showWhyEgb, setShowWhyEgb] = useState<boolean>(false);

    const outlet = useOutlet();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            setHideArticles(isDesktop());
            setShowWhyEgb(true);
        } else {
            setHideArticles(false);
            setShowWhyEgb(false);
        }
    }, [location.pathname]);

    const [state, setState] = useState({
        height: '500px',
        key: '',
        marginBottom: '0px',
        marginTop: '70px',
        pathname: null
    });

    const contentRef = useRef(null);
    const wrapRef = useRef(null);

    useEffect(() => {
        setViewport();
    }, []);

    const setViewport = () => {
        const maxWidth = 979;

        if (!mobileAndTabletCheck() && screen.width < maxWidth) {
            const viewport = 'width=1200, maximum-scale=4.0';
            $('meta[name=viewport]').attr('content', viewport);
        }
    };

    const updateHeight = (height?: string | number) => {
        const marginTop = height ? height : $('.header').outerHeight();
        const marginBottom = isDesktop() ? 0 : $('.tap_bar').outerHeight();

        setState(prevState => ({
            ...prevState,
            marginBottom: `${marginBottom}px`,
            marginTop: `${marginTop}px`
        }));
    };

    useEffect(() => {
        applyMetaTags();
        tableStore.startUpdateLines();

        const betId = location.hash.substr(1);

        if ((checkingPages.isPlayPage() || isMainPage()) && betId && betId.length > 0 && !betId.includes('auth')) {
            showBetForm({id: betId});
        }

        updateHeight();

        const handleResize = () => updateHeight();
        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize, false);
        };
    }, []);

    const isHomePageClassName = () => isHomePage() ? ' home-page' : '';

    const hiddenStream = () => isDesktop() ? isDepositOrCashoutPage() && !streamStore.stream : isDepositOrCashoutPage();

    return <div className="app__inner">
        <Header updateHeight={updateHeight}/>
        <div className="notification">
            <NotificationCenter />
        </div>
        <div className="notification">
            <ToastList />
        </div>

        <div
            className={`main-block${isHomePageClassName()}`}
            style={{
                marginBottom: state.marginBottom,
                marginTop: state.marginTop
            }}>
            <div ref={wrapRef} className="wrap wrap--content">
                <div id="react-content" className="content">
                    <div ref={contentRef} className="content__inner ">
                        {!isDesktop() && isBetPage(true) && <SmartStoriesMobile/>}

                        { outlet && isCasinoPage()
                            ? outlet
                            : <StreamsPlayPage hiddenStream={hiddenStream()}>
                                {outlet}
                            </StreamsPlayPage>}

                        {isHomePage() && !isDesktop() && isGuest() && <BarLoginMobile/>}

                        {isDesktop() && <Footer/>}
                    </div>
                </div>
                {homeOrDesktop() && !isChatMobile() && <SiteVersion.Aside
                    hideArticles={hideArticles}
                    showWhyEgb={showWhyEgb}
                    hideChat={isHomePage() && !isDesktop()}
                />}
                {!isDesktop() && isHomePage() && <Footer/>}
            </div>
        </div>
        <BetSlipContainer/>
        <PokerContainer/>
        {!window.SITE_DATA.MOBILE_WRAPPER && <CookiesPolicy/>}

        {!isDesktop() && <TapBar/>}
    </div>;
});

export default AppPageWrapper;
