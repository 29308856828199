import React, {PureComponent, ReactNode} from 'react';
import VolumeChanger from 'app/components/volume_changer';
import SiteVersion from '../SiteVersion';

class VolumeControl extends PureComponent {
    render(): ReactNode {
        return <div className="header__control">
            <VolumeChanger/>
        </div>;
    }
}

SiteVersion.volumeControl = () => <VolumeControl/>;
