import SiteVersion from '../SiteVersion';

SiteVersion.PlayNowList = [
    {
        path: '/play/bets',
        section: 'user_menu_table'
    },
    {
        path: '/play/express',
        section: 'user_menu_express'
    },
    {
        path: '/play/live',
        section: 'user_menu_live'
    }
];
