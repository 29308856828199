import React, {Component, ReactNode} from 'react';
import SiteVersion from 'site_version/SiteVersion';
import {NavLink} from 'react-router-dom';
import I18n from 'app/i18n';
import {
    closeMenuAndScrollToTop,
    getLinkPath
} from 'app/utils';
import lootboxIconMobile from 'app/components/svg/lootboxIconMobile.jsx';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';

interface MobileMenuProps {
    articlesStore?: ArticlesStore;
}

@inject('articlesStore')
@observer
export default class MobileMenu extends Component<MobileMenuProps> {
    constructor(props: MobileMenuProps) {
        super(props);
        this.props.articlesStore.initPromoNew();
    }

    render(): ReactNode {
        const {activePromoNewCount} = this.props.articlesStore;
        return <>
            <div className="main-nav__subtitle">{I18n.t('navigation')}</div>
            <div className="main-nav__menu">
                {SiteVersion.HeaderMobileMenu.map(item => <NavLink
                    key={item.section}
                    to={getLinkPath(item.path)}
                    onClick={closeMenuAndScrollToTop}
                    className={
                        ({isActive}) => `main-nav__link main-nav__link main-nav__link__${
                            item.section
                        }${ isActive ? ' active-link' : ''}`
                    }
                >

                    <div className="main-nav__link-icon">
                        {item.section === 'lootboxes'
                            ? <span className="svg__icon">
                                {lootboxIconMobile}
                            </span>
                            : <svg height="19px" width="19px"><use
                                xlinkHref={`#svg-${item.svg}`}/></svg>
                        }

                    </div>
                    <div className="main-nav__link-text">
                        {I18n.t(`menu_links.${item.section}`)}
                        {item.section === 'promo' ? ` (${activePromoNewCount})` : ''}
                    </div>
                </NavLink>)}
            </div>
            {SiteVersion.streamsListMobile()}
        </>;
    }
}
