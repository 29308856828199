import $ from 'jquery';
import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {betMoney, timeStampToTime} from 'app/utils';
import BonusInterfaces from 'app/interfaces/BonusInterface';
import CountdownTimer from 'app/components/bet_form/timer';
import BonusProgress from 'app/components/my_profile/BonusProgress';
import {modalConfirm} from 'app/utils/modals/popups';
import {UserStore} from 'app/store/UserStore';
import {ADVANCE_TYPES} from 'app/utils/balance/betsBalance';
import {UserBonusesStore} from 'app/store/UserBonusesStore';

interface Props {
    bonus: BonusInterfaces
    userStore: UserStore
    userBonusesStore: UserBonusesStore
}

export default class Bonus extends PureComponent<Props> {
    isActiveBonus = (bonus: BonusInterfaces): boolean => bonus.active;

    showBonusProgress = (bonusId: number, e: MouseEvent<HTMLButtonElement>): void => {
        const element = $(e.currentTarget);
        element.toggleClass('opener-active');
        element.closest('.bonuses__have-bonus').find(`[data-content=${bonusId}]`).slideToggle();
    };

    renderName = (bonus: BonusInterfaces): string => {
        switch (bonus.type) {
        case 'Advance': return `${I18n.t('instant_bonus')} (${I18n.t('all')})`;
        case 'Bets_Advance': return `${I18n.t('instant_bonus')} (${I18n.t('bonus_types.bets_advance')})`;
        case 'Casino_Advance': return `${I18n.t('instant_bonus')} (${I18n.t('bonus_types.casino_advance')})`;
        case 'Cashback_Casino_Advance': return `${I18n.t('cashback_bonus')} (${I18n.t('bonus_types.casino_advance')})`;
        case 'Cashback_Bets_Advance': return `${I18n.t('cashback_bonus')} (${I18n.t('bonus_types.bets_advance')})`;
        case 'Birthday_Casino_Advance': return `${I18n.t('birthday_bonus')} (${I18n.t('bonus_types.casino_advance')})`;
        case 'Birthday_Bets_Advance': return `${I18n.t('birthday_bonus')} (${I18n.t('bonus_types.bets_advance')})`;
        default: return I18n.t('classic');
        }
    };

    cancelBonus = (bonus: BonusInterfaces): void => {
        const {cancelBonus} = this.props.userBonusesStore;
        const {user} = this.props.userStore;
        modalConfirm(`${I18n.t('cancel_bonus_message')}${ADVANCE_TYPES.includes(bonus.type) && bonus.active
            ? I18n.t('cancel_advance_message', {amount: user.bonus_balance})
            : ''}`, () => {
            cancelBonus(bonus.id);
        });
    };

    onClick = (): void => this.cancelBonus(this.props.bonus);

    render(): ReactNode {
        const {bonus} = this.props;
        return <li className="bonuses__item" key={bonus.id}>
            <div className="bonuses__have-bonus">
                <div className={`bonuses__stat-holder${this.isActiveBonus(bonus) ? '' : ' result-lost'}`}>
                    <div className="bonuses__stat">
                        <span>ID:</span>
                        <span>{bonus.id}</span>
                    </div>
                    <div className="bonuses__stat">
                        <span>{this.renderName(bonus)}:</span>
                        <span>{betMoney({amount: bonus.sum})}</span>
                    </div>
                    <div className="bonuses__stat">
                        <span>{I18n.t('user.date_label_bonuses')}:</span>
                        <span>{timeStampToTime(bonus.created_at, true)}</span>
                    </div>
                    <div className="bonuses__stat">
                        <span>{I18n.t('user.time_left_label_bonuses')}:</span>
                        <span><CountdownTimer time={bonus.time_left} plainText/></span>
                    </div>
                    <div className="bonuses__buttons">
                        <button className="button-adaptive button-adaptive_instyle" onClick={this.onClick}>
                            {I18n.t('button_cancel')}
                        </button>
                        {this.isActiveBonus(bonus) && <div className="bonuses__opener">
                            <button
                                className="act bonuses__opener-icon"
                                onClick={this.showBonusProgress.bind(this, bonus.id)}
                            >
                                <svg height="9px" width="12px">
                                    <use xlinkHref="#svg-arr-select"/>
                                </svg>
                            </button>
                        </div>}
                    </div>
                </div>
                {this.isActiveBonus(bonus) && <div
                    className="bonuses__container"
                    style={{display: 'none'}}
                    data-content={bonus.id}
                >
                    <BonusProgress bonus={bonus}/>
                </div>}
            </div>
        </li>;
    }
}
