import React from 'react';
import SiteVersion from '../SiteVersion';
import UserNav from '../common/UserNav';

const USER_MENU_SECTIONS = [
    {
        icon_height: '16px',
        icon_width: '16px',
        path: '/my_profile/settings',
        translate: 'profile'
    },
    {
        icon_height: '14px',
        icon_width: '18px',
        path: '/messages',
        svg: 'messages',
        translate: 'messages'
    },
    {
        icon_height: '14px',
        icon_width: '18px',
        path: '/my_bets',
        svg: 'bets',
        translate: 'rates'
    },
    {
        icon_height: '21px',
        icon_width: '21px',
        path: '/my_profile/bonuses',
        svg: 'bonuses',
        translate: 'my_bonuses'
    }
];

SiteVersion.userNav = props => <UserNav sections={USER_MENU_SECTIONS} {...props}/>;
