import React, {Component} from 'react';
import I18n from 'app/i18n';

export default class EmptyOffers extends Component {
    render() {
        return <div className="offer offer--empty">
            <div className="subtitle">{this.props.subtitle}</div>
            <p>{I18n.t('empty_trade_description')}</p>
        </div>;
    }
}