import React, {ChangeEvent, FormEvent, MouseEvent, PureComponent, ReactNode} from 'react';
import $ from 'jquery';
import I18n from 'app/i18n';
import logEvent from 'app/amplitude/log_event';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {handleInvalidCSRFToken} from 'app/utils';
import SmartLoader from 'app/components/reusable/SmartLoader';

const ONE_KB = 1024;
const FIVE_MB = 5;

interface DocumentType {
    id: number
    created_at: string
    updated_at: string
    status: string
}

interface UploadButtonProps {
    created_for_id?: number
    created_for_type: string
    maxDocumentsLoaded: boolean
    onUpload?: (documents: DocumentType[]) => void
    justButton?: boolean
    label?: string
    thinButton?: boolean
    singleDocument?: boolean
    noForm?: boolean
}

interface UploadButtonState {
    document: File
    filename: string
    loaded: boolean
}

export default class UploadButton extends PureComponent<UploadButtonProps, UploadButtonState> {
    constructor(props: UploadButtonProps) {
        super(props);
        this.state = {
            document: null,
            filename: null,
            loaded: false
        };
    }

    fileRef = React.createRef<HTMLInputElement>();

    submitHandler = (e: FormEvent<HTMLFormElement>): void => {
        e && e.preventDefault();
        this.setState({loaded: true});

        $.ajax('/verified_documents', {
            contentType: false,
            data: this.formData(),
            processData: false,
            statusCode: {
                200: data => {
                    logEvent('DOCUMENTS_SENT');
                    this.setState({
                        document: null,
                        filename: null
                    });
                    this.props.onUpload && this.props.onUpload(data);
                    toastSuccess(I18n.t('user.successfully_uploaded'));
                },
                413: () => toastError(I18n.t('user.uploaded_file_too_large')),
                422: error => {
                    const {responseJSON} = error;

                    if (responseJSON) {
                        toastError(responseJSON.error);
                    }
                }
            },
            type: 'POST'
        }).promise().fail(handleInvalidCSRFToken).always(this.disableLoading);
    };

    documentUploadHandler = (e: ChangeEvent<HTMLInputElement>): void => {
        const file = e.target.files.item(0);

        if (file && file.size / ONE_KB / ONE_KB > FIVE_MB) {
            e.target.value = null;
            toastError(I18n.t('user.uploaded_file_too_large'));
            return;
        }

        this.setState({
            document: file,
            filename: file.name
        }, () => {
            if (this.props.justButton) {
                this.submitHandler(null);
            }
        });
    };

    formData = (): FormData => {
        const {document} = this.state;
        const {singleDocument} = this.props;
        const formData = new FormData();
        formData.append('user_verified_document[document]', document);
        this.setCreatedFor(formData);
        if (singleDocument) {
            formData.append('single', '1');
        }
        return formData;
    };

    setCreatedFor = (formData: FormData): void => {
        const {created_for_id, created_for_type} = this.props;

        if (created_for_type) {
            if (created_for_id) {
                formData.append('user_verified_document[created_for_id]', `${created_for_id}`);
            }
            formData.append('user_verified_document[created_for_type]', created_for_type);
        }
    };

    disableLoading = (): void => this.setState({loaded: false});

    chooseFile = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        this.fileRef.current.click();
    };

    render(): ReactNode {
        const {loaded} = this.state;
        const {maxDocumentsLoaded, justButton, label, thinButton, noForm} = this.props;
        const res = <>
            <label
                className={`support__link${maxDocumentsLoaded ? ' support__disabled' : ' cursor-pointer'}`}
                style={{
                    display: justButton ? 'none' : 'inline-block',
                    marginRight: '10px'
                }}
            >
                <input
                    ref={this.fileRef}
                    type="file"
                    style={{display: 'none'}}
                    disabled={maxDocumentsLoaded}
                    onChange={this.documentUploadHandler}
                />
                <span className="upload-button">
                    {this.state.filename || I18n.t('user.choose_file_label')}
                </span>
            </label>

            {loaded && <SmartLoader
                loaderClassName="btn smart-loader_theme-dark_no-margin button-adaptive_transparent"/>}

            {justButton
                ? !loaded && <button
                    onClick={this.chooseFile}
                    className={`button-adaptive ${maxDocumentsLoaded
                        ? 'button-adaptive_disable'
                        : `button-adaptive_${label ? `full${thinButton ? '-thin' : ''}` : 'transparent'}`}`}
                >
                    {label || I18n.t(maxDocumentsLoaded ? 'screenshot_uploaded' : 'upload_screenshot')}
                </button>
                : !loaded && <button
                    className={`btn btn--big-on-mobile${maxDocumentsLoaded ? ' btn--disable' : ''}`}
                    type="submit"
                    style={{float: 'right'}}
                    disabled={maxDocumentsLoaded}
                >
                    {I18n.t('user.upload_button')}
                </button>}
        </>;

        return noForm
            ? res
            : <form onSubmit={this.submitHandler} style={{display: 'flex'}}>{res}</form>;
    }
}
