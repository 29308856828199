import React, {ReactNode} from 'react';
import PokerBoard from 'app/components/poker/poker_board/PokerBoard';
import PokerPot from 'app/components/poker/poker_pot/PokerPot';
import PokerPlayers from 'app/components/poker/poker_players/PokerPlayers';
import PokerControllerPanel from 'app/components/poker/poker_controller_panel/PokerControllerPanel';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';

interface Props {
    pokerStore?: PokerStore
}

@inject('pokerStore')
@observer
export default class PokerRoom extends React.Component<Props> {
    render(): ReactNode {
        return <div className="poker-room">
            <div className="poker-table">
                <PokerPot/>
                <PokerBoard/>
                <PokerPlayers/>
            </div>
            <PokerControllerPanel/>
        </div>;
    }
}
