import Cookies from 'js-cookie';
import {artifactStore} from 'app/store/global';

import stream_sound from 'sounds/stream_has_started.mp3';
import line_created_sound from 'sounds/bet_created.mp3';
import line_closed_sound from 'sounds/bet_result.mp3';
import notification_sound from 'sounds/incoming_mail.mp3';
import money_sound from 'sounds/money_sound.mp3';
import incubator_bet from 'sounds/incubator_bet.mp3';
import incubator_bet_max from 'sounds/incubator_bet_max.mp3';

const musicPlayer = {
    defaultSounds: {
        incubator_bet,
        incubator_bet_max,
        line_closed_sound,
        line_created_sound,
        money_sound,
        notification_sound,
        stream_sound
    },
    volume: function (volume) {
        function userSound() {
            return Cookies.get('volume') || Number(window.settings.user.sound_enabled);
        }

        return typeof volume !== 'undefined' ? Cookies.set('volume', volume, {expires: 365 * 5}) : userSound();
    },
    getTrack: function (soundtrack) {
        return (artifactStore.sounds || {})[soundtrack] || this.defaultSounds[soundtrack];
    },
    play: function (soundtrack) {
        const audio = new Audio(this.getTrack(soundtrack));
        audio.volume = this.volume();
        const promise = audio.play();

        if (promise) {
            promise.catch(error => {
                console.error(error);
            });
        }
    }
};

export default musicPlayer;
