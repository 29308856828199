import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import GoBackButton from 'app/components/reusable/GoBackButton';
import {goToLinkIncubator, linkIncubatorHelpStaticPage, linkIncubatorNew} from 'app/utils/app_links';
import {isDesktop} from 'app/utils';
import IncubatorVolumeButton from 'app/components/pages/incubator/IncubatorVolumeButton';
import IncubatorChatButton from 'app/components/pages/incubator/IncubatorChatButton';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'app/store/UserStore';

interface Props {
    title: string
    hasShift: boolean
    showButtonCreateLine: boolean
    showIncubatorGoBack?: boolean
    userStore?: UserStore
}

@inject('userStore')
@observer
export default class IncubatorGoBack extends PureComponent<Props> {
    goToIncubator = (): void => goToLinkIncubator();

    render(): React.ReactNode {
        const {title, showButtonCreateLine, showIncubatorGoBack, userStore} = this.props;
        const {enable_incubator} = userStore.user;

        return <div className="wrapper-incubator-go-back">
            <div className="inner-incubator-go-back">
                <GoBackButton onClick={this.goToIncubator}/>
                <h1 className="title">{title}</h1>
                {!isDesktop() && showButtonCreateLine && this.props.hasShift &&
                    <div className="buttons-incubator-mobile">
                        <IncubatorVolumeButton/>
                        <IncubatorChatButton/>
                    </div>}
            </div>
            {showIncubatorGoBack && <div className="buttons-incubator-go-back">
                <Link
                    className="button-adaptive button-adaptive_instyle"
                    to={linkIncubatorHelpStaticPage()}
                >
                    <span className="button-icon">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-incubator-about"/>
                        </svg>
                    </span>
                    <span className="button-name">{I18n.t('incubator_user_guide')}</span>
                </Link>
                {enable_incubator && showButtonCreateLine &&
                <Link to={linkIncubatorNew()}
                    className="button-adaptive button-adaptive_instyle">
                    {I18n.t('create_line')}
                </Link>}
            </div>}
        </div>;
    }
}
