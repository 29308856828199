import React, {PureComponent, ReactNode} from 'react';
import SiteVersion from '../SiteVersion';
import UserPromotion from 'app/components/user/UserPromotion';
import {getPromoUrl} from 'app/utils/getPromoUrl';
import {userStore} from 'app/store/global';
import {USER_PROMOTION_DEPOSIT, USER_PROMOTION_FRIEND, USER_PROMOTION_DISCONT} from 'app/utils/Consts';

class UserPromotions extends PureComponent {
    render(): ReactNode {
        return <div className="user-promotions">
            {!userStore.user.first_deposit && <UserPromotion
                linkUserPromotion="/deposits"
                itemClassName="promotion-first-deposit"
                localePromotionTitle="user_promotion.first_deposit_title"
                svgIconName="first-deposit"
                localePromotionText="user_promotion.first_deposit_text"
                bannerType="promo"
                nameCoockie={USER_PROMOTION_DEPOSIT}
                showClose
            />}
            <UserPromotion
                linkUserPromotion="/my_profile/referrals"
                itemClassName="invite-friend"
                localePromotionTitle="user_promotion_invite_title"
                svgIconName="plus"
                localePromotionText="user_promotion_invite_text"
                bannerType="referral"
                nameCoockie={USER_PROMOTION_FRIEND}
                showClose
            />
            <UserPromotion
                linkUserPromotion={getPromoUrl()}
                itemClassName="promotion-discount"
                localePromotionTitle="user_promotion_discount_title"
                svgIconName="discount"
                localePromotionText="user_promotion_discount_text"
                bannerType="promo"
                nameCoockie={USER_PROMOTION_DISCONT}
                showClose
            />
        </div>;
    }
}

SiteVersion.userPromotions = props => <UserPromotions {...props}/>;
