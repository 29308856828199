import React, {ReactNode} from 'react';
import PokerMainPot from 'app/components/poker/poker_pot/PokerMainPot';

export default class PokerPot extends React.Component {
    render(): ReactNode {
        return <div className="poker-pot">
            <PokerMainPot/>
            {/* <div className="poker-side-pots-group">
                <div className="poker-side-pots">
                    <PokerSidePot/>
                    <PokerSidePot/>
                </div>
                <div className="poker-side-pots">
                    <PokerSidePot/>
                    <PokerSidePot/>
                    <PokerSidePot/>
                </div>
            </div> */}
        </div>;
    }
}
