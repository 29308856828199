import React, {ChangeEvent, PureComponent, ReactNode} from 'react';
import DepositSystem from '../form/DepositSystem';
import SelectPicker from 'app/components/reusable/SelectPicker';
import I18n from 'app/i18n';
import {selectPickerCollection} from 'app/components/reusable/SelectCollection';
import Post from 'app/utils/Post';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import {userStore} from 'app/store/global';
import {subscribeCryptoAddress, unsubscribe} from 'modules/WsSubscription';
import CopyableAddress from 'app/components/deposit/coinspaid/CopyableAddress';
import {AddressesCurrenciesType, loadAddresses} from 'app/components/deposit/coinspaid/CoinspaidUtils';
import SelectBonusType from 'app/components/user/SelectBonusType';
import {inject, observer} from 'mobx-react';
import {userStoreInterface} from 'app/store/UserStore';
import {amountWithSign} from 'app/utils/format';

const CRYPTOCURRENCY_PROTOCOLS: {[key: string]: string} = {
    BCH: 'bitcoincash',
    BTC: 'bitcoin',
    BUSD: 'binance-usd',
    DOGE: 'dogecoin',
    ETC: 'ethereumclassic',
    ETH: 'ethereum',
    LTC: 'litecoin',
    TFL: 'trueflip',
    WBTC: 'wrapped-bitcoin',
    XMR: 'monero',
    XRP: 'xrp'
};
const ONE_SECOND = 1000;

@inject('userStore')
@observer
export default class CoinspaidDeposit extends PureComponent<userStoreInterface> {
    state = {
        addresses: {},
        creating: false,
        currencies: [],
        currency: 'BTC',
        max_amount: 0,
        promoActiveItem: 1
    };

    addressIntervalId = null;

    subscription = null;

    componentDidMount = (): void => {
        this.loadAddresses();

        this.subscription = subscribeCryptoAddress(userStore.user, ({data: {event}}) => {
            if (event === 'create') {
                this.loadAddresses();
            }
        });
    };

    componentWillUnmount = (): void => {
        this.subscription = unsubscribe(this.subscription);
    };

    loadAddresses = (): void => {
        loadAddresses((res: AddressesCurrenciesType) => {
            this.setState({
                ...res,
                creating: Object.values(res.addresses).filter(a => a.address === null).length !== 0
            });
        });
    };

    changeCurrency = (e: ChangeEvent<HTMLSelectElement>): void => {
        if (this.state.creating) {
            return;
        }
        this.setState({currency: e.target.value});
    };

    createAddress = (): void => {
        this.setState({creating: true});
        new Post({
            params: {currency: this.state.currency},
            url: '/deposits/coinspaid'
        })
            .execute()
            .then(() => setTimeout(this.loadAddresses, ONE_SECOND));
    };

    hasSelectedAddress = (): boolean => {
        const {addresses, currency} = this.state;
        return addresses[currency];
    };

    selectedAddress = (): string => {
        if (!this.hasSelectedAddress()) {
            return null;
        }

        const {addresses, currency} = this.state;
        return addresses[currency].address;
    };

    selectedTag = (): string => {
        if (!this.hasSelectedAddress()) {
            return null;
        }

        const {addresses, currency} = this.state;
        return addresses[currency].tag;
    };

    minLimit = (): { min_limit: number } => {
        if (!this.hasSelectedAddress()) {
            return null;
        }

        const {currencies, currency} = this.state;
        return currencies.find(c => c.currency === currency).min_amount;
    };

    availableCurrencies = (): CollectionInterface[] => selectPickerCollection(
        this.state.currencies.map(c => [this.currencyName(c.currency, c.network), c.currency])
    );

    currencyName = (currency: string, network?: string): string => {
        if (network) {
            return `${currency} (${I18n.t('network')}: ${network})`;
        }

        return currency;
    };

    currencyProtocol = (): string => CRYPTOCURRENCY_PROTOCOLS[this.state.currency];

    qrImgSrc = (): string => `https://quickchart.io/qr?text=${this.currencyProtocol()}:${this.selectedAddress()}&margin=2&size=150`;

    render(): ReactNode {
        const {creating, currency} = this.state;
        return <DepositSystem>
            <div className="deposit-form__inputs">
                <div className="deposit-form__content">
                    <div className="form__row">
                        <div className="form__row-item">
                            <label className="form__row-label">{I18n.t('choose_coin')}</label>
                            <div className="field ">
                                <SelectPicker
                                    onChange={this.changeCurrency}
                                    value={currency}
                                    collection={this.availableCurrencies()}
                                    placeholder={I18n.t('choose_currency')}
                                    className="select-bank"
                                />
                            </div>
                        </div>
                    </div>
                    {!this.props.userStore.user.first_deposit && <SelectBonusType coinspaid/>}
                    {!this.hasSelectedAddress() && !creating && <div className="form__row">
                        <button className="button-adaptive" onClick={this.createAddress}>
                            {I18n.t('user.make_deposit_label')}
                        </button>
                    </div>}
                </div>
            </div>
            {creating && <div className="loader"/>}
            {this.hasSelectedAddress() && !creating && <div className="deposit-qr-code">
                {this.currencyProtocol() && <a
                    href={`${this.currencyProtocol()}:${this.selectedAddress()}`}
                    target="_blank"
                    rel="noreferrer"
                    className="qr-code"
                >
                    <img
                        alt={this.selectedAddress()}
                        src={this.qrImgSrc()}
                    />
                </a>}

                <div className="qr-info">
                    <CopyableAddress label={I18n.t('payment_address')} address={this.selectedAddress()}/>
                    {this.selectedTag() && <CopyableAddress
                        label={I18n.t('tag')}
                        address={this.selectedTag()}
                        grayLabel
                    />}
                    <div className="card-deposit__limits">
                        <div className="card-deposit-limits">
                            <span className="card-deposit-limits__title">{I18n.t('min_sum')}</span>
                            <span className="card-deposit-limits__total">
                                {`${this.minLimit()} ${this.state.currency}`}
                            </span>
                        </div>
                        {this.state.max_amount > 0 && <div className="card-deposit-limits">
                            <span className="card-deposit-limits__title">{I18n.t('max_sum')}</span>
                            <span className="card-deposit-limits__total">
                                {amountWithSign(this.state.max_amount)}
                            </span>
                        </div>}
                    </div>
                </div>
            </div>}
        </DepositSystem>;
    }
}
