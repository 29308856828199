import React, {Component, ReactNode} from 'react';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {inject, observer} from 'mobx-react';
import SmartPageLoader from 'app/components/reusable/smart_loaders/SmartPageLoader';

interface ItemInterface {
    children?: React.ReactNode
    articlesStore?: ArticlesStore
}

@inject('articlesStore')
@observer
export default class PreviewNewList extends Component<ItemInterface> {
    render(): ReactNode {
        return this.props.articlesStore.loadingMainArticles
            ? <div className="loading-promo-articles">
                <SmartPageLoader/>
            </div>
            : <div className="list-promo-articles">
                {this.props.children}
            </div>;
    }
}
