import SiteVersion from '../SiteVersion';
import {getPromoUrl} from 'app/utils/getPromoUrl';

SiteVersion.HeaderMobileMenu = [
    {
        path: '/shop/money',
        section: 'shop',
        svg: 'shop'
    },
    {
        path: '/news',
        section: 'news',
        svg: 'news'
    },
    {
        path: getPromoUrl(),
        section: 'promo',
        svg: 'promo_action'
    },
    {
        path: '/shop/lootboxes',
        section: 'lootboxes',
        svg: 'lootboxes'
    },
    {
        path: '/contacts',
        section: 'contacts',
        svg: 'contact'
    },
    {
        path: '/faq',
        section: 'faq',
        svg: 'faq'
    }
];
