import React, {Component, ReactNode} from 'react';
import BetSlipMenuButton from './BetSlipMenuButton';
import {betMoney, getLinkPath} from 'app/utils';
import {restorePlayMoney} from 'app/components/user/menu';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';

import {isRealMoney} from 'app/lines/isRealMoney';
import {ARTIFACT_MONEY, PLAY_MONEY, REAL_MONEY, DEMO_MONEY} from 'app/utils/Consts';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {isDemoMoney} from 'app/lines/isDemoMoney';
import {isArtifactMoney} from 'app/lines/isArtifactMoney';
import {userStore} from 'app/store/global';

interface BetSlipMenuTabsProps {
    handleSwitchTab: (type: string) => void
    onClickDeposits: () => void
    type: string
    loadingForm: boolean
    play_money: string
    lite: boolean
    money: string
}

export default class BetSlipMenuTabs extends Component<BetSlipMenuTabsProps> {
    render(): ReactNode {
        const {
            type, handleSwitchTab, loadingForm, play_money, lite, money, onClickDeposits
        } = this.props;
        return <div className="bet-slip-menu">
            <div className="bet-slip-menu__item">
                <BetSlipMenuButton
                    disabled={loadingForm}
                    active={userStore.user.demo ? isDemoMoney(type) : isRealMoney(type)}
                    switchTab={handleSwitchTab}
                    type={userStore.user.demo ? DEMO_MONEY : REAL_MONEY}
                >
                    <span className="value">{money}</span>
                </BetSlipMenuButton>
                <Link
                    to={getLinkPath('/deposits')}
                    className="button-go-deposit"
                    onClick={onClickDeposits}
                >
                    <svg className="svg-default plus"><use xlinkHref="#svg-add"/></svg>
                </Link>
            </div>
            <div className="bet-slip-menu__item">
                <BetSlipMenuButton
                    disabled={loadingForm}
                    active={isPlayMoney(type)}
                    switchTab={handleSwitchTab}
                    type={PLAY_MONEY}
                >
                    <span className="value">
                        {`${I18n.t('pm')}: ${betMoney({}, play_money, false, true)}`}
                    </span>
                </BetSlipMenuButton>
                <button className="button-reload-pm" onClick={restorePlayMoney}>
                    <svg className="svg-default">
                        <use xlinkHref="#svg-reboot"/>
                    </svg>
                </button>
            </div>
            {!lite && <div className="bet-slip-menu__item">
                <BetSlipMenuButton
                    disabled={loadingForm}
                    active={isArtifactMoney(type)}
                    switchTab={handleSwitchTab}
                    type={ARTIFACT_MONEY}
                >
                    <span className="value">{I18n.t('skins')}</span>
                </BetSlipMenuButton>
            </div>}
        </div>;
    }
}
