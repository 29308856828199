import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import {amountCurrency} from 'app/utils/amountCurrency';
import I18n from 'app/i18n';

interface BetSlipLimitButtonProps {
    amount: string
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void
    incubator?: boolean
    subClass?: string
    title: string
    type: string
}

export default class BetSlipLimitButton extends PureComponent<BetSlipLimitButtonProps> {
    render(): ReactNode {
        const {amount, onClick, subClass, title, type, incubator} = this.props;
        const className = `limit-value ${subClass ? subClass : ''}`;
        const text = amountCurrency(amount, type);
        return <div className="cell-box">
            <span className="sub-title">{title}</span>
            {onClick && !incubator
                ? <button className={className} onClick={onClick}>{text}</button>
                : <span className={className}>{incubator ? I18n.t('unlimited') : text}</span>
            }
        </div>;
    }
}
