import {inject, observer} from 'mobx-react';
import React from 'react';
import {gameImage} from 'app/utils';
import Row from './index';
import AdvantageLine from './advantage_line';
import HandicapLine from './HandicapLine';
import {isRootLine} from 'app/utils/line/rootLine';
import {hasNestedLines} from 'app/lines/hasNestedLines';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {isHandicapLine} from 'app/lines/isHandicapLine';
import {createSerialRendering} from 'app/lines/createSerialRendering';
import MapWithNestedLines from 'app/components/table/row/MapWithNestedLines';

@inject('tableStore', 'userStore')
@observer
export default class MainLine extends Row {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showNestedBets: this.props.showNestedBets
        };
    }

    componentDidMount() {
        const {showNestedBets} = this.props;
        showNestedBets && this.collapseRow(showNestedBets);
    }

    renderTableExpand = () => <span className="table-expand table-expand--inner"/>;

    renderGameImage() {
        const {isNestedBet, bet} = this.props;
        return isNestedBet ? null : gameImage(bet.filter);
    }

    nestedLineComponent = (line, props) => {
        if (isAdvantageLine(line)) {
            return <AdvantageLine key={`advantage-line-${line.id}`} {...props}/>;
        }
        if (isHandicapLine(line)) {
            return <HandicapLine key={`handicap-line-${line.id}`} {...props}/>;
        }
        if (line.type_map === true && line.nested_bets_count > 1) {
            return <MapWithNestedLines key={`nested-line-${line.id}`} show={this.state.showNestedBets} {...props} />;
        }
        return <MainLine key={`nested-line-${line.id}`} {...props}/>;
    };

    nestedLineWithoutNestedToNested = nestedBets => {
        if (isRootLine(this.props.bet)) {
            return nestedBets.filter(line => line.nested_bets_count > 0).length === 0;
        }
        return false;
    };

    render() {
        const nestedBets = this.nestedBets();
        const {showNestedBets} = this.state;
        const {bet, bet: {color, has_advantage, active_nested_bets_count, hash_id}, activeCoef, coefOnClick,
            isExpress, seo, tableStore, userStore, isNestedBet} = this.props;
        const rowClass = `table-bets__${isNestedBet ? 'inner1-row' : 'main-row'}
        js-expand-row${showNestedBets ? ' main-row-openned' : ''} has-already-bet`;
        const colorClass = `table-bets__${isNestedBet ? 'inner-row' : 'main-row' }-holder
        holder--color${color}${has_advantage ? ' advantage' : ''}`;

        return <div id={`line-row-${hash_id}`} className={rowClass}>
            {isNestedBet
                ? <div className={colorClass}>
                    <div className={`table-bets__content
                        ${isNestedBet ? 'nested' : ''} table-bets__border-bottom`}>
                        {this.renderButtonPlus('table-expand--main', active_nested_bets_count, hasNestedLines(bet))}
                        {this.renderBody(true)}
                    </div>
                </div>
                : <div className={colorClass}>
                    {this.renderButtonPlus('table-expand--main', active_nested_bets_count, hasNestedLines(bet))}
                    {this.renderBody()}
                </div>}

            {nestedBets.length > 0 && <div
                className={`table-bets__main-row-container js-main-container
                ${this.nestedLineWithoutNestedToNested(nestedBets) ? 'first-line' : 'no_first_nested'}`}
                ref={this.container}
            >
                {showNestedBets && [
                    ...nestedBets.map(nestedLine => {
                        const gamerActiveCoef = activeCoef && activeCoef(nestedLine.id);
                        const props = {
                            activeCoef,
                            bet: nestedLine,
                            coefOnClick,
                            color: nestedLine.color,
                            gamerActiveCoef,
                            isAdvantage: isAdvantageLine(nestedLine),
                            isExpress,
                            isNestedBet: true,
                            nested: !nestedLine.nested_bets_count > 0,
                            parentBet: bet,
                            seo,
                            serial: createSerialRendering(nestedLine, activeCoef),
                            showNestedBets,
                            tableStore,
                            userStore,
                            ut: nestedLine.ut
                        };
                        return this.nestedLineComponent(nestedLine, props);
                    })
                ]}
            </div>}
        </div>;
    }
}
