import React, {PureComponent, ReactNode} from 'react';
import SiteVersion from '../SiteVersion';
import ListOfArtifacts from 'app/components/my_profile/artifacts/artifacts_header/ListOfArtifacts';

class UserArtifacts extends PureComponent {
    render(): ReactNode {
        return <div className="header__control">
            <ListOfArtifacts />
        </div>;
    }
}

SiteVersion.userArtifacts = () => <UserArtifacts/>;
