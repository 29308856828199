import {userStore} from 'app/store/global';
import ChangedDepositProfileFields from 'app/components/auth/ChangedDepositProfileFields';
import React, {Component, ReactElement, ReactNode} from 'react';
import I18n from 'app/i18n';
import {getLinkPath, goToRoute} from 'app/utils';
import {NavLink} from 'react-router-dom';
import {DepositHistory} from './DepositHistory';
import {getDepositComponent} from './DepositComponents';
import Systems from 'app/components/deposit/Systems';
import {inject, observer} from 'mobx-react';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import CryptoInformation from 'app/components/deposit/information/CryptoInformation';
import SmartPageLoader from 'app/components/reusable/smart_loaders/SmartPageLoader';
import Get from 'app/utils/Get';
import withRouter from 'app/utils/navigate/customWithRouter';
import openHelpDeskSupport from 'app/utils/openHelpDeskSupport';

export interface DepositPageProps extends P2pDepositsStoreProps, DepositsStoreProps {
    params?: {
        system_slug: string
    }
}

class DepositPage extends Component<DepositPageProps> {
    componentDidMount(): void {
        const {loading} = this.props.depositsStore;

        if (loading) {
            new Get({url: getLinkPath('/deposits')})
                .execute()
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        this.props.p2pDepositsStore.update(null, null, data.current_p2p_deposits);
                        this.props.depositsStore.update(data);

                        if (this.props.params.system_slug) {
                            const {system_slug} = this.props.params;
                            const {systems} = this.props.depositsStore;
                            this.selectSystem(systems.find(system => system.slug === system_slug));
                        }
                    }
                });
        }
    }

    componentDidUpdate(oldProps: DepositPageProps): void {
        const {system_slug} = this.props.params;

        if (oldProps.params.system_slug !== system_slug) {
            const {systems} = this.props.depositsStore;
            this.selectSystem(systems.find(system => system.slug === system_slug));
        }
    }

    componentWillUnmount = (): void => {
        this.props.depositsStore.unLoad();
        this.props.depositsStore.selectPaymentSystem(null);
        this.props.p2pDepositsStore.selectPaymentSystem(null);
    };

    selectSystem = (system: PaymentSystemInterface): void => {
        this.props.depositsStore.selectPaymentSystem(system);
        this.props.p2pDepositsStore.selectPaymentSystem(system);
        goToRoute(`/deposits${system ? `/${system.slug}` : ''}`);
    };

    getSystems(recommended = true): PaymentSystemInterface[] {
        const {systems} = this.props.depositsStore;
        return systems.filter(system => system.recommended === recommended && !system.registered);
    }

    getRegisteredSystems(): PaymentSystemInterface[] {
        const {systems} = this.props.depositsStore;
        return systems.filter(system => system.registered);
    }

    getSystemComponent = (): ReactElement => {
        const {deposit_ui_component} = this.props.depositsStore.currentPaymentSystem;
        const UiComponent: React.ComponentType<DepositPageProps> = getDepositComponent(deposit_ui_component);

        if (!UiComponent) {
            throw new Error(`invalid ui component ${deposit_ui_component}`);
        }

        return <UiComponent {...this.props}/>;
    };

    render(): ReactNode {
        const system = this.props.depositsStore.currentPaymentSystem;
        const {loading, success, message} = this.props.depositsStore;

        if (loading) {
            return <div className="deposit">
                <div className="title">
                    <h2>{I18n.t('user.deposit')}</h2>
                </div>
                <SmartPageLoader/>
            </div>;
        }

        if (!userStore.fullRegistered()) {
            return <div className="deposit">
                <div className="title">
                    <h1>{I18n.t('user.fill_in_fields_to_make_a_deposit')}:</h1>
                </div>
                <div className="form-content deposit-form form form-egb used-chosen">
                    <div className="deposit-form__inputs">
                        <ChangedDepositProfileFields depositsStore={this.props.depositsStore} {...userStore.user}/>
                    </div>
                </div>
            </div>;
        }
        if (!success) {
            return <div className="deposit">
                <div className="title">
                    <h2>{I18n.t('user.deposit')}</h2>
                </div>
                {message === 'disabled_country'
                    ? <div className="deposit__info">
                        {I18n.t('disabled_country.head')}<br/>
                        {I18n.t('disabled_country.first_body')}&ensp;
                        <a className="cursor-pointer" onClick={openHelpDeskSupport}>{I18n.t('disabled_country.click')}</a>&ensp;
                        {I18n.t('disabled_country.second_body')}
                    </div>
                    : <div className="deposit__info" dangerouslySetInnerHTML={{__html: message}}/>}
            </div>;
        }
        return <div className={`deposit${system ? ' deposit__selectedSystem' : ''}` }>
            {system
                ? <>
                    <div className="title title__selectedSystem">
                        <NavLink
                            to={getLinkPath('/deposits')}
                            className={({isActive}) => isActive ? 'active' : ''}
                        >
                            <>
                                <svg height="8px" width="5px">
                                    <use xlinkHref="#svg-back"/>
                                </svg>
                                {I18n.t('button_back_label')}
                            </>
                        </NavLink>
                        <h2>
                            {`${I18n.t('user.deposit')} ${system.name}`}
                        </h2>
                    </div>
                    {this.getSystemComponent()}
                </>
                : <>
                    <div className="title">
                        <h2>{I18n.t('user.deposit')}</h2>
                    </div>
                    <div className="payment-systems payment-systems__deposit">
                        <CryptoInformation/>
                        <Systems
                            title={I18n.t('registered_payment_method')}
                            systems={this.getRegisteredSystems()}
                            selectSystem={this.selectSystem}
                        />
                        <Systems
                            title={I18n.t('recommended_for_your_country')}
                            systems={this.getSystems()}
                            selectSystem={this.selectSystem}
                        />
                        <Systems
                            title={I18n.t('other_methods')}
                            systems={this.getSystems(false)}
                            selectSystem={this.selectSystem}
                        />
                    </div>
                </>}
            <div className="history-transferred">
                <DepositHistory/>
            </div>
        </div>;
    }
}

export default withRouter(inject('depositsStore', 'p2pDepositsStore')(observer(DepositPage)));
