import {action, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';
import BonusInterface from 'app/interfaces/BonusInterface';
import BonusDataInterface from 'app/interfaces/BonusDataInterface';
import {UserStore, userStoreInterface} from 'app/store/UserStore';
import Post from 'app/utils/Post';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import Websocket from 'modules/Websocket';

export interface UpdateBonusesType {
    bonuses?: BonusInterface[],
    bonuses_data?: BonusDataInterface,
    bonusesLoading?: boolean
}

export class UserBonusesStore {
    @observable bonuses: BonusInterface[] = [];

    @observable bonuses_data: BonusDataInterface = null;

    @observable bonusesLoading = false;

    @observable bonusDataLoading = false;

    userStore: UserStore;

    subscriptions = [];

    constructor({userStore}: userStoreInterface) {
        this.userStore = userStore;
        if (this.userStore.user.id) {
            this.updateUserBonusData({});
            this.getUserBonusesData();
            this.getUserBonusesStats();
        }
        makeObservable(this);
    }

    @action
    updateUserBonusData({
        bonuses, bonuses_data
    }: UpdateBonusesType): void {
        this.bonuses = bonuses || [];
        this.bonuses_data = bonuses_data;
    }

    @action
    updateBonusesLoading(loading = false): void {
        this.bonusesLoading = loading;
    }

    @action
    updateBonusDataLoading(loading = false): void {
        this.bonusDataLoading = loading;
    }

    @action
    getUserBonusesData(): void {
        this.updateBonusDataLoading(true);
        new Get({url: '/my_profile/bonuses_data'})
            .execute()
            .then(data => data.json())
            .then(data => {
                this.updateBonusDataLoading();
                this.updateStore({...data});
                this.userStore.updateBonuses(data.bonuses);
            });
    }

    @action
    getUserBonusesStats(loading = true): void {
        this.updateBonusesLoading(loading);
        new Get({url: '/my_profile/bonuses_stats'})
            .execute()
            .then(data => data.json())
            .then(data => {
                this.updateBonusesLoading();
                this.updateStore({...data});
            });
    }


    @action
    updateStore({bonuses, bonuses_data}:
            { bonuses?: BonusInterface[], bonuses_data?: BonusDataInterface, loading: boolean}): void {
        if (bonuses) {
            this.bonuses = bonuses;
        }
        if (bonuses_data) {
            this.bonuses_data = bonuses_data;
        }
    }

    @action
    removeBonusAfterCancel(id: number): void {
        const objWithIdIndex = this.bonuses.findIndex(obj => obj.id === id);
        const notFoundIndex = -1;
        const recordFound = objWithIdIndex > notFoundIndex;

        if (recordFound) {
            this.bonuses.splice(objWithIdIndex, 1);
        }
    }

    cancelBonus = (id: number): void => {
        new Post({
            params: {bonus_id: id},
            url: '/user/cancel_bonus'
        }).execute()
            .then((response: Response) => response.json())
            .then(response => {
                if (response.success) {
                    toastSuccess(I18n.t('cancelled_bonus_message', {id}));
                    this.removeBonusAfterCancel(id);
                } else {
                    toastError(response.message);
                }
            });
    };

    listen(): void {
        this.getUserBonusesData();
        this.getUserBonusesStats(false);
    }

    unsubscribe(userId: number): void {
        let subscription = this.subscriptions[`${userId}`];
        if (subscription) {
            Websocket.unsubscribe(subscription);
            subscription = null;
        }
    }
}
