import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {amountCurrency} from 'app/utils/amountCurrency';

interface ButtonPlaceBetProps {
    amount: string
    confirm: boolean
    loading: boolean
    type: string
    win: string
    handleSetConfirm: () => void
    handleMakeBet: (e: MouseEvent<HTMLButtonElement>) => void
    availableButton: boolean
    suggestBet: boolean
}

export default class ButtonPlaceBet extends PureComponent<ButtonPlaceBetProps> {
    render(): ReactNode {
        const {
            amount, type, win, loading, suggestBet,
            confirm, handleSetConfirm, handleMakeBet, availableButton
        } = this.props;
        return <div className="wrapper-button-place-bet">
            {loading && !suggestBet
                ? <SmartLoader/>
                : <button
                    className={`button-place-bet ${availableButton ? '' : 'disabled'}`}
                    disabled={!availableButton}
                    onClick={handleMakeBet}
                >
                    {confirm
                        ? <span className="top" onClick={handleSetConfirm}>{I18n.t('accept_changes')}</span>
                        : <>
                            <span className="top">{I18n.t(suggestBet ? 'suggest_bet' : 'place_a_bet_full')}
                                {amount && <b> {amountCurrency(amount, type)}</b>}
                            </span>
                            {amount && <span className="you-can-win">
                                {I18n.t('bet_you_can_win_label')}: <b>{amountCurrency(win, type)}</b>
                            </span>}
                        </>}
                </button>}
        </div>;
    }
}
