import React from 'react';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import {TableStore} from 'app/store/TableStore';
import {UserStore} from 'app/store/UserStore';
import Row from 'app/components/table/row/index';
import {inject, observer} from 'mobx-react';
import {isRootLine} from 'app/utils/line/rootLine';
import {hasNestedLines} from 'app/lines/hasNestedLines';
import {hasNestedBetWithUserBet} from 'app/lines/hasNestedLinesWithUserBet';
import HandicapLine from 'app/components/table/row/HandicapLine';
import {ActiveCoef, createSerialRendering} from 'app/lines/createSerialRendering';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {BLUE, GREEN, RED} from 'admin/components/bet_form/utils/LineConsts';
import {WHITE} from 'app/lines/line_helpers';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {date, time} from 'app/utils';

interface GroupContainerProps {
    nestedLines: LineInterface[]
    bet?: LineInterface;
    name: string;
    tableStore?: TableStore
    userStore?: UserStore
    activeCoef: ActiveCoef;
    coefOnClick: number
    isExpress: boolean
    seo: string
    isNestedBet: boolean
    showNestedBets: boolean
    handleChildStateChange?: (stateChanged?: boolean) => void
}

export interface GroupContainerState {
    loading: boolean;
    showModal: boolean;
    show: boolean
    showNestedBets: boolean;
    widgetUrl: string;
}

interface RowProps {
    activeCoef: ActiveCoef
    bet: LineInterface
    coefOnClick: number
    color: number
    gamerActiveCoef: {
        on: number;
    };
    isAdvantage: boolean
    isExpress: boolean
    isNestedBet: boolean
    nested: boolean
    parentBet: LineInterface
    seo: string
    serial: string
    tableStore: TableStore
    userStore: UserStore
    ut: number
}

@inject('tableStore', 'userStore')
@observer
export default class GroupContainer extends Row {
  props: GroupContainerProps;

  groupContainer: React.RefObject<HTMLDivElement>;

  constructor(props: GroupContainer) {
      super(props);
      this.groupContainer = React.createRef();
      this.state = {
          loading: false,
          show: false,
          showModal: false,
          showNestedBets: false,
          widgetUrl: ''
      };
  }

  componentDidUpdate(prevState: GroupContainerState): void {
      if (prevState.show === this.state.show) {
          this.props.handleChildStateChange(this.state.show);
      }
  }

  nestedLineWithoutNestedToNested(nestedBets: LineInterface[]): boolean {
      if (isRootLine(this.props.bet)) {
          return nestedBets.filter(line => line.nested_bets_count > 0).length === 0;
      }
      return false;
  }

  renderPlus(hasNestedBets: boolean, nestedBetsCount: number): JSX.Element {
      const plusClass = `table-expand__plus-holder${this.state.show ? ' table-expand--active' : ''}`;
      return hasNestedBets
          ? <div className={plusClass}>
              <svg className="svg-default svg-h">
                  <use xlinkHref="#svg-plus-new-h"/>
              </svg>
              <svg className="svg-default svg-v">
                  <use xlinkHref="#svg-plus-new-v"/>
              </svg>
              <span className="table-expand__plus-num">{nestedBetsCount}</span>
          </div>
          : null;
  }

  renderButtonPlus(className: string, nestedBetsCount: number, hasNestedBets = false): JSX.Element {
      const {loading} = this.state;
      const buttonClass = `act table-expand ${className}${hasNestedBetWithUserBet(this.props.bet)
          ? ' table-expand--already-bet'
          : ''}`;
      return <button className={buttonClass} onClick={this.handleShowNestedBets}>
          {loading
              ? <SmartLoader/>
              : this.renderPlus(hasNestedBets, nestedBetsCount)}
      </button>;
  }

  handleShowNestedBets(): void {
      this.setState({show: !this.state.show});
      this.props.handleChildStateChange();
  }

  nestedLineComponent(line: LineInterface, props: RowProps): JSX.Element {
      return <HandicapLine key={`handicap-line-${line.id}`} {...props}/>;
  }

  defineColor(nestedLines: LineInterface[]): number {
      if (nestedLines.every(item => item.color === BLUE)) {
          return BLUE;
      } else if (nestedLines.some(item => item.color === BLUE)) {
          return GREEN;
      } else if (nestedLines.every(item => item.color === RED)) {
          return RED;
      } else if (nestedLines.every(item => item.color === WHITE)) {
          return WHITE;
      }
      return this.props.bet.color;
  }

  renderLiveDate(bet: LineInterface): JSX.Element {
      return bet.live
          ? <div className="table-bets__live">
              <span className="table-bets__live-text">{I18n.t('live_label')}</span>
          </div>
          : <div className="table-bets__date">
              <span>{date(bet.date)}</span>
              <span>{time(bet.date)}</span>
          </div>;
  }

  renderExpand(nestedLines: LineInterface[], bet: LineInterface): JSX.Element {
      return <div className="table-bets__content advantage table-bets__border-bottom" onClick={this.handleShowNestedBets}>
          {this.renderButtonPlus('table-expand--main', nestedLines.length, hasNestedLines(bet))}
          <div className="table-bets__content-row cursor-pointer">
              <span className="table-expand table-expand--inner"/>
              <div className="table-bets__content-row">
                  <div className="table-bets__mobile-row handicap-row">
                      {this.renderLiveDate(bet)}
                      <div className="table-bets__icon"></div>
                      <div className="table-bets__event">
                          <span>{this.props.name}</span>
                      </div>
                  </div>
              </div>
              <div className="table-bets__play cursor-pointer"></div>
              <div className="table-bets__stat"></div>
          </div>
      </div>;
  }

  renderOneLine(line: LineInterface): JSX.Element {
      const {activeCoef, coefOnClick, isExpress, seo, tableStore, userStore} = this.props;
      const {color} = line;
      const gamerActiveCoef = activeCoef && activeCoef(line.id);
      const props = {
          activeCoef,
          bet: line,
          coefOnClick,
          color,
          gamerActiveCoef,
          isAdvantage: isAdvantageLine(line),
          isExpress,
          isNestedBet: true,
          nested: !(line.nested_bets_count > 0),
          parentBet: line.parent,
          seo,
          serial: createSerialRendering(line, activeCoef),
          tableStore,
          userStore,
          ut: line.ut
      };

      return this.nestedLineComponent(line, props);
  }


  render(): JSX.Element {
      const {nestedLines, bet} = this.props;

      return <div ref={this.groupContainer}
          className={`table-bets__inner-row-holder holder--color${this.defineColor(nestedLines)} group-row`}>
          {nestedLines.length > 1
              ? <>{this.renderExpand(nestedLines, bet)}

                  {this.state.show && <>
                      {nestedLines.length > 0 && <div className="table-bets__inner1-rows">{[
                          ...this.props.nestedLines.map(nestedLine => {
                              const {activeCoef, coefOnClick, isExpress, seo, tableStore, userStore} = this.props;
                              const {color} = nestedLine;
                              const gamerActiveCoef = activeCoef && activeCoef(nestedLine.id);
                              const props = {
                                  activeCoef,
                                  bet: nestedLine,
                                  coefOnClick,
                                  color,
                                  gamerActiveCoef,
                                  isAdvantage: isAdvantageLine(nestedLine),
                                  isExpress,
                                  isNestedBet: true,
                                  nested: !(nestedLine.nested_bets_count > 0),
                                  parentBet: nestedLine.parent,
                                  seo,
                                  serial: createSerialRendering(nestedLine, activeCoef),
                                  tableStore,
                                  userStore,
                                  ut: nestedLine.ut
                              };

                              return this.nestedLineComponent(nestedLine, props);
                          })
                      ]}</div>}
                  </>
                  }</>
              : this.renderOneLine(this.props.nestedLines[0])}
      </div>;
  }
}
