import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

export default class PokerMenuButtonPause extends Component {
    render(): ReactNode {
        return <button className="poker-menu-container-button pause">
            <svg className="svg-default"><use xlinkHref="#svg-pause-icon"/></svg>
            <span className="name">{I18n.t('poker_bet_sit_out')}</span>
        </button>;
    }
}
