import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {disabledButton} from 'app/components/deposit/util/userVerifiedDocuments';
import UserVerifiedDocumentInterface from 'app/interfaces/UserVerifiedDocumentInterface';
import SupportButton from 'app/components/SupportButton';

interface CurrentP2pDepositProps {
    user_verified_documents: UserVerifiedDocumentInterface[]
    waiting: boolean
}

const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;

export default class P2pDepositSteps extends Component<CurrentP2pDepositProps> {
    render(): ReactNode {
        const disabledLoaderButton = disabledButton(this.props.user_verified_documents);
        const {waiting} = this.props;
        return <div className="deposit-list-steps">
            <div className="deposit-list-item">
                <div className="deposit-step">
                    {waiting
                        ? <span className="deposit-step__number">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-icon-done"/>
                            </svg>
                        </span>
                        : <span className="deposit-step__number active">{STEP_1}</span>}
                    <div className="deposit-step__text">
                        <p>{waiting ? I18n.t('deposit_step_1') : I18n.t('deposit_step_1_start')}</p>
                    </div>
                    {!waiting && <div className="deposit-step__description">
                        <p>{I18n.t('deposit_step_start')}</p>
                    </div>}
                </div>
            </div>
            <div className="deposit-list-item">
                <div className="deposit-step">
                    <span className={`deposit-step__number${waiting ? ' active' : ''}`}>{STEP_2}</span>
                    <div className="deposit-step__text">
                        <p>{I18n.t('deposit_step_2')}</p>
                    </div>
                    <div className="deposit-screenshot">
                        <span className={`deposit-screenshot__icon${disabledLoaderButton ? ' active' : ''}`}>
                            <svg className="svg-default icon-timer">
                                <use xlinkHref="#svg-document-timer"/>
                            </svg>
                            <svg className="svg-default icon-done">
                                <use xlinkHref="#svg-document-done"/>
                            </svg>
                        </span>
                        <div className="deposit-screenshot__text">
                            <p>{I18n.t('deposit_step_screenshot')}</p>
                        </div>
                    </div>
                    <div className="deposit-support">
                        <span className="deposit-support__icon">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-icon-chat"/>
                            </svg>
                        </span>
                        <div className="deposit-support__text">
                            <p>{I18n.t('deposit_step_write_support_1')}
                                <SupportButton hideIcon/>
                                {I18n.t('deposit_step_write_support_2')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="deposit-list-item">
                <div className="deposit-step">
                    <span className="deposit-step__number">{STEP_3}</span>
                    <div className="deposit-step__text">
                        <p>{I18n.t('deposit_step_3')}</p>
                    </div>
                </div>
            </div>
        </div>;
    }
}
