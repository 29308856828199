import React, {ReactNode} from 'react';
import PokerButtonTakePlace from 'app/components/poker/poker_players/poker_take_place/PokerButtonTakePlace';
import PokerDealer from 'app/components/poker/poker_players/poker_dealer/PokerDealer';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';

interface Props {
    position: number
    isBigBlind: boolean
    player?: PlayerInterface
}

const HALF_OF_PLAYERS = 3;

export default class PokerPlace extends React.Component<Props> {
    render(): ReactNode {
        const {position, player, isBigBlind} = this.props;
        const bet_position = position <= HALF_OF_PLAYERS ? 'bet-bottom' : 'bet-top';

        return <div className={`poker-player place-${position} ${bet_position}`}>
            <PokerButtonTakePlace/>
            {player && <>
                <div className="player-cards">
                    <span className="player-card-first"></span>
                    <span className="player-card-second"></span>
                </div>
                <div className="poker-player-about my-player">
                    <span className="name" title="RyanPress">RyanPress</span>
                    <span className="deposit">$10,590.11</span>
                </div>
                <div className="poker-player-bet">
                    <span className="amount">$100.11</span>
                </div>
                {isBigBlind && <PokerDealer/>}
            </>}
        </div>;
    }
}
