import $ from 'jquery';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import I18n from 'app/i18n';
import {AuthMenu} from 'app/components/auth/auth';
import {NavLink, Link} from 'react-router-dom';
import {
    accordionMobMenu,
    closeMobileMenu,
    getLinkPath,
    isDesktop,
    scrollToTop, setElementCookies,
    userSignedIn
} from 'app/utils';
import ListOfLanguages from 'app/components/langs';
import ListOfArtifacts from 'app/components/my_profile/artifacts/artifacts_header/ListOfArtifacts';
import VolumeChanger from 'app/components/volume_changer';
import SiteVersion from 'site_version/SiteVersion';
import {inject, observer} from 'mobx-react';
import MobileMenu from 'app/components/layouts/MobileMenu';
import {casinoGamesStore} from 'app/store/global';
import {isShowAppNotification} from 'app/utils/isShowAppNotification';
import {APP_INSTALL_ASKED} from 'app/utils/Consts';
import UserMenuBalance from 'site_version/common/UserMenuBalance';
import AppLink from 'site_version/common/AppLink';
import {UserStore} from 'app/store/UserStore';
import {getTableLinkPath} from 'app/utils/link_helper';
// @ts-ignore
import logoFramedSvg from 'images/svg_icons/logo-framed.svg';
import {userBalance} from 'app/utils/balance/userBalance';

interface HeaderPropsInterface {
    userStore?: UserStore
    updateHeight(height?: number): void
}

const APP_LINK_SHOW = 'appLinkShow';
const MIN_HEIGHT_HEADER = 57;
export const classNameActiveLinkHeader = ({isActive}: {isActive: boolean}): string => `main-nav__link ${isActive ? 'active-link' : ''}`;

// eslint-disable-next-line complexity,max-lines-per-function,max-statements
const HeaderComponent = (props: HeaderPropsInterface) => {
    const mobileMenuContainer: React.RefObject<HTMLDivElement> = useRef(null);

    const getLocalStorageItem = (name: string): boolean => window.localStorage.getItem(name) === 'true';

    const [, forceUpdate] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [liteForm, setLiteForm] = useState(props.userStore.user.lite);
    const [showAppLink, setShowAppLink] = useState(!getLocalStorageItem(APP_LINK_SHOW));
    const [showAppNotification, setShowAppNotification] = useState(isShowAppNotification());

    const handleReload = useCallback(() => {
        setIsRefresh(true);
        window.location.reload();
    }, [setIsRefresh]);

    useEffect(() => {
        accordionMobMenu($('.js-mobileMenu-opener'), $('.js-mobileMenu-container')).init();
        document.addEventListener('mousedown', handleClickOutsideMenu);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMenu);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = (): void => {
        forceUpdate(n => n + 1);
    };

    const handleClickOutsideMenu = (e: Event): void => {
        if (mobileMenuContainer.current && !mobileMenuContainer.current.contains(e.target as Node)) {
            closeMobileMenu(e);
        }
    };

    const handleNotificationClose = useCallback(() => {
        setLocalStorageItem(APP_INSTALL_ASKED);
        setShowAppNotification(false);
        props.updateHeight(MIN_HEIGHT_HEADER);
    }, [setShowAppNotification]);

    const setLocalStorageItem = (name: string): void => {
        window.localStorage.setItem(name, 'true');
    };

    const handleAppLinkClose = useCallback(() => {
        setLocalStorageItem(APP_LINK_SHOW);
        setShowAppLink(false);
    }, [setShowAppLink]);

    const clickHotHidden = (): void => {
        scrollToTop();
        props.userStore.changeUserHotIconStatus();
    };

    const onClickHeaderLink = (section: string): void => {
        if (section === 'casino') {
            casinoGamesStore.clearAllFilters();
        }
        scrollToTop();
    };

    const onClickLite = useCallback(() => {
        const {user: {lite}, updateLite} = props.userStore;
        setLiteForm(!lite);
        if (userSignedIn()) {
            updateLite(!lite);
        } else {
            setElementCookies('lite', lite ? '0' : '1');
            window.location.reload();
        }
    }, []);


    const {user} = props.userStore;
    const balance = userBalance(user);
    return <div className={`header${liteForm ? ' app-lite' : ''}`}>
        {showAppNotification &&
                <div className="notification__item notification__item--top js-close-block">
                    <button className="act notification__close js-close-block-btn"
                        onClick={handleNotificationClose}>
                        <svg height="14px" width="14px">
                            <use xlinkHref="#svg-close"/>
                        </svg>
                    </button>
                    <div className="notification__content">
                        <div className="notification__content-wrap">
                            <div className="app-info__column--header">
                                <div className="app-info__column--header--img logo-framed logo-framed--brown">
                                    <Link to={getLinkPath('/')}/>
                                    <img src={logoFramedSvg} alt="EGB.com"/>
                                </div>
                                <div className="app-info__column--header--text">
                                    <div className="app-info__column--title text-highlighted">
                                        {I18n.t('egb_egamingbets')}
                                    </div>
                                    <div className="app-info__column--subtitle">
                                        {I18n.t('install_the_latest_version_of_the_application')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="notification__button-holder">
                            <Link
                                to={getLinkPath('/apps')}
                                className="btn"
                                onClick={handleNotificationClose}
                            >
                                {I18n.t('download')}
                            </Link>
                        </div>
                    </div>
                </div>}
        <div className="wrap wrap--header">
            <div className="header__aside-left">
                <div className="mobile-user">
                    <nav className="main-nav">
                        <button className="act main-nav__opener js-mobileMenu-opener">
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <div className="main-nav__holder-mobile js-mobileMenu-container">
                            <div ref={mobileMenuContainer} className="main-nav__holder-mobile-inner">
                                <MobileMenu/>
                                <div className="main-nav__controls">
                                    <VolumeChanger mobile={true}/>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                {SiteVersion.logoLink && SiteVersion.logoLink()}
                <div className={`switch-version ${liteForm ? 'light' : 'pro'}-version`} onClick={onClickLite}>
                    <span className="name">{liteForm ? 'Lite' : 'PRO'}</span>
                    <span className="switch"/>
                </div>
            </div>
            <div className="header__content">
                <nav className="main-nav">
                    <div className="main-nav__holder">
                        {SiteVersion.HeaderMenu.map(item => <NavLink
                            key={item.section}
                            to={item.path === '/play/bets' ? getTableLinkPath(item.path) : getLinkPath(item.path)}
                            className={classNameActiveLinkHeader}
                            onClick={() => onClickHeaderLink(item.section)}
                        >
                            {I18n.t(`menu_links.${item.section}`)}
                        </NavLink>)}
                        {!isDesktop() && <NavLink
                            to={getLinkPath('/blog')}
                            className={classNameActiveLinkHeader}>
                            {I18n.t('menu_links.blog')}
                        </NavLink>}
                    </div>
                </nav>
            </div>
            <div className="header__aside-right">
                {isDesktop() &&
                        <div className="header__controls">
                            {SiteVersion.headerLootbox &&
                                    SiteVersion.headerLootbox({clickHotHidden,
                                        hot_icon_lootbox_enabled: user.hot_icon_lootbox_enabled})}
                            {userSignedIn() && <div className="header__control  header__control-balance">
                                <Link
                                    title={I18n.t('open_deposit_page')}
                                    to={getLinkPath('/deposits')}
                                    className="header__control-opener  header__control-balance-opener"
                                    onClick={scrollToTop}
                                >
                                    <UserMenuBalance full_balance={balance}/>
                                </Link>
                            </div>}
                            {isDesktop() && showAppLink && <AppLink handleAppLinkClose = {handleAppLinkClose}/>}
                            {SiteVersion.volumeControl && SiteVersion.volumeControl()}
                            <div className="header__control">
                                <ListOfLanguages/>
                            </div>
                            {userSignedIn() && SiteVersion.userArtifacts && SiteVersion.userArtifacts()}
                        </div> }
                <AuthMenu link={getLinkPath('/my_profile#profile')}/>
            </div>
            <div className="header__mobile-controls">
                {!liteForm && userSignedIn() && <div className="header__mobile-control">
                    <ListOfArtifacts/>
                </div>}
                <div className="header__mobile-control">
                    <ListOfLanguages/>
                </div>
            </div>
            {!isDesktop() && userSignedIn() && window.SITE_DATA.MOBILE_WRAPPER &&
                    <div className="refresh-mobile">
                        <div className={`refresh-mobile__button ${isRefresh ? ' active' : ''}`}
                            onClick={handleReload}>
                            <span className="refresh-mobile__icon">
                                <svg height="16px" width="16px">
                                    <use xlinkHref="#svg-refresh-mobile"/>
                                </svg>
                            </span>
                        </div>
                    </div>}
        </div>
    </div>;
};

const Header = inject('userStore')(observer(HeaderComponent));
export default Header;
