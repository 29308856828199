import React from 'react';
import I18n from 'app/i18n';
import {NavLink} from 'react-router-dom';
import SiteVersion from 'site_version/SiteVersion';
import {getClassNameActiveLink} from 'app/utils/link_helper';
import {Outlet} from 'react-router';
import {getLinkPath} from 'app/utils';

export default class MyProfileTabs extends React.PureComponent {
    render(): React.ReactNode {
        return <div className="tabs js-tabs tabs--top">
            <div className="tabs__header js-custom-scroll">
                <div className="tabs__inner__header">
                    <NavLink
                        to={getLinkPath('/my_profile/settings')}
                        className={getClassNameActiveLink}>
                        {I18n.t('settings')}
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/my_profile/bonuses')}
                        className={getClassNameActiveLink}>
                        {I18n.t('bonuses')}
                    </NavLink>
                    {SiteVersion.profileMenu()}
                    <NavLink
                        to={getLinkPath('/my_profile/notifications_settings')}
                        className={getClassNameActiveLink}>
                        {I18n.t('notification_settings.title')}
                    </NavLink>
                </div>
            </div>
            <Outlet />
        </div>;
    }
}
