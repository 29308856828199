import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import {inject, observer} from 'mobx-react';
import BuyButton from 'app/components/shop/lootboxes/buy_button';
import {isGuest, scrollToTop} from 'app/utils';
import {toastSuccess} from 'app/components/toasts/liteToast';
import {currencyItem} from 'app/utils/ShopItemPriceFormat';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';
import withRouter from 'app/utils/navigate/customWithRouter';
import GoBackButton from 'app/components/reusable/GoBackButton';
import {OtherItemStore} from 'app/store/OtherItemStore';
import {otherItemDescription} from 'app/components/my_profile/artifacts/otherItemDescription';
import {otherItemName} from 'app/components/my_profile/artifacts/otherItemName';

interface OtherItemDetailsProps {
    otherItemStore?: OtherItemStore;
    params?: {
        id: number;
    }
}
class OtherItemDetails extends Component<OtherItemDetailsProps> {
    componentDidMount(): void {
        this.props.otherItemStore.getItem(this.props.params.id);
        scrollToTop();
    }

    componentWillUnmount(): void {
        this.props.otherItemStore.updateItem(null);
    }

    componentDidUpdate(props): void {
        if (this.props.params.id !== props.params.id) {
            this.props.otherItemStore.getItem(this.props.params.id);
        }
    }

    get currency() {
        return new URLSearchParams(location.search).get('currency');
    }

    afterBuy = () => {
        toastSuccess(I18n.t('purchase_was_successful_message'));
    };

    render(): ReactNode {
        const {item} = this.props.otherItemStore;

        if (!item) {
            return <SmartFullPageLoader/>;
        }
        return <div className="market-details">
            <div className="market-details--info">
                <div className="market-details--info--content">
                    <div className="market-details--info--description">
                        <div className="market-details--info--img-wrap">
                            <div className="market-details--top-mobile">
                                <h1 className="market-details--info--header-mobile">{otherItemName(item)}</h1>
                                <GoBackButton/>
                            </div>
                            <div className="market-details--info--img">
                                <div className="market-details--info--inner--img">
                                    <SimpleArtifactImage artifact={item}/>
                                </div>
                            </div>
                            <div className="market-details--group-buttons">
                                {!isGuest() && item && <BuyButton
                                    {...item}
                                    currency={currencyItem(this.currency)}
                                    customText={I18n.t('buy')}
                                    afterBuy={this.afterBuy}
                                />}
                            </div>
                        </div>
                        <div className="market-details--info--desc">
                            <div className="market-details--top">
                                <h1 className="market-details--info--desc-name">{otherItemName(item)}</h1>
                                <GoBackButton/>
                            </div>
                            <div className="market-details--info--desc-text"
                                dangerouslySetInnerHTML={{__html: otherItemDescription(item)}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(inject('otherItemStore')(observer(OtherItemDetails)));
