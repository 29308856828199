import UserInterface from 'app/interfaces/UserInterface';

export const ADVANCE_TYPES = [
    'Advance',
    'Bets_Advance',
    'Casino_Advance',
    'Cashback_Casino_Advance',
    'Cashback_Bets_Advance',
    'Birthday_Casino_Advance',
    'Birthday_Bets_Advance'
];

export const BETS_ADVANCE = [
    'Advance',
    'Bets_Advance',
    'Cashback_Bets_Advance',
    'Birthday_Bets_Advance'
];

export const betsBalance = (user: UserInterface): string => {
    const {demo, demo_balance, bonuses, full_balance, balance} = user;

    if (demo) {
        return String(demo_balance);
    }

    return bonuses && bonuses[0] && BETS_ADVANCE.includes(bonuses[0].type) ? full_balance : balance;
};

