import React, {Component} from 'react';
import {PageContent} from 'app/components/page_content';
import {inject, observer} from 'mobx-react';

const WhyEgb = inject('pageContentStore')(observer(class WhyEgbImpl extends Component {
    render() {
        const content = this.props.pageContentStore.content || {};
        return <PageContent contentUrl="/">{!this.props.aside
            ? <div className="page-description">
                <div className="page-description__heading">
                    <h1 dangerouslySetInnerHTML={{__html: content.why_egb_heading}}/>
                </div>
                <div className="page-description__content-holder">
                    <div className="page-description__content" dangerouslySetInnerHTML={{__html: content.why_egb_content}}/>
                </div>
            </div>
            : <div className="aside-page-description mobile mobile--show">
                <div className="aside-page-description__heading">
                    <h1 dangerouslySetInnerHTML={{__html: content.why_egb_heading}}/>
                </div>
                <div className="aside-page-description__content">
                    <div className="aside-page-description-item">
                        <div className="aside-page-description-item__text" dangerouslySetInnerHTML={{__html: content.why_egb_content}}/>
                    </div>
                </div>
            </div>}
        </PageContent>;
    }
}));

export default WhyEgb;
