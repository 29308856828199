import React, {Component, MouseEvent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {openRegistration} from 'app/components/auth/auth';
import {isGuest} from 'app/utils';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {BetSlipStore} from 'app/store/BetSlipStore';
import ButtonPlaceBet from 'app/components/bet_slip/place_bet/ButtonPlaceBet';
import {isRealMoney} from 'app/lines/isRealMoney';

interface ButtonPlaceBetProps {
    artifactBetStore?: ArtifactBetStore
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('artifactBetStore', 'controller', 'betSlipStore')
@observer
export default class ButtonPlaceBetContainer extends Component<ButtonPlaceBetProps> {
    handleMakeBet = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (isGuest()) {
            openRegistration();
            return;
        }

        const {betSlipStore: {loading}, controller: {makeBet}} = this.props;

        if (loading) {
            return;
        }
        makeBet();
    };

    handleSetConfirm = (): void => {
        this.props.controller.setPlaceBetConfirm();
    };

    render(): ReactNode {
        const {type, loading, amount, win, confirm, warning, incubator2, incubator2WithCoef} = this.props.betSlipStore;
        const {artifactBetStore, controller: {userStore: {user}}} = this.props;

        const available = !warning || isRealMoney(type) && user.enable_incubator2;
        const {artifactsToBet} = artifactBetStore;
        const availableButton = Boolean(available && (amount || artifactsToBet.length > 0));
        const suggestBet = incubator2 && !incubator2WithCoef && user.enable_incubator2;
        return <ButtonPlaceBet
            amount={amount}
            confirm={confirm}
            loading={loading}
            type={type}
            win={win}
            suggestBet={suggestBet}
            handleSetConfirm={this.handleSetConfirm}
            handleMakeBet={this.handleMakeBet}
            availableButton={availableButton}
        />;
    }
}
