import React, {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import {closeMenuAndScrollToTop, getLinkPath} from 'app/utils';
import {countedTitle} from 'app/utils/countedTitle';
import SiteVersion from '../SiteVersion';
import {getPromoUrl} from 'app/utils/getPromoUrl';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {inject, observer} from 'mobx-react';

interface UserNavBottomProps {
    articlesStore?: ArticlesStore
}

@inject('articlesStore')
@observer
class UserNavBottom extends Component<UserNavBottomProps> {
    constructor(props: UserNavBottomProps) {
        super(props);
        this.props.articlesStore.initPromoNew();
    }

    render(): ReactNode {
        return <>
            <Link
                className="user-nav__item"
                to={getLinkPath('/my_profile/referrals')}
                onClick={closeMenuAndScrollToTop}
            >
                <span className="user-nav__icon">
                    <svg height="19px" width="18px">
                        <use xlinkHref="#svg-referrals"/>
                    </svg>
                </span>
                <span>{I18n.t('my_referrals')}</span>
            </Link>
            <Link
                className="user-nav__item"
                to={getLinkPath(getPromoUrl())}
                onClick={closeMenuAndScrollToTop}
            >
                <span className="user-nav__icon">
                    <svg height="15px" width="20px">
                        <use xlinkHref="#svg-promo_action"/>
                    </svg>
                </span>
                <span>{countedTitle(I18n.t('promo_actions'), this.props.articlesStore.activePromoNewCount)}</span>
            </Link>
        </>;
    }
}

SiteVersion.userNavBottom = props => <UserNavBottom {...props}/>;
