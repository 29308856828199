import {action, computed, makeObservable, observable} from 'mobx';
import {getLinkPath} from 'app/utils';
import {getLangParams} from 'app/components/chat/chat_utils';
import Get from 'app/utils/Get';
import {MessageInterface} from 'app/interfaces/stores/chat/MessageInterface';
import prepareMessages from 'app/utils/chat/prepareMessages';
import $ from 'jquery';
import {modalAlert} from '../utils/modals/popups';
import Delete from 'app/utils/Delete';
import Post from 'app/utils/Post';
import {defaultChatLang} from './chat/defaultChatLang';
import {LANGUAGES} from 'app/utils/Consts';
import {toastError} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';

export interface LanguageInterface {
    currentTime: number,
    name: string
}

interface UpdateParamsInterface {
    currentLang?: string
}

interface DataInterface {
    ct?: number,
    lang?: string,
    umc?: boolean
}

export interface ResponseInterface {
    m: MessageInterface[]
}

const FIVE_SECONDS = 5000;

export class ChatStore {
    @observable
        activeLangs: string[] = [];

    @observable
        currentLang: string = '';

    @observable
        disabledButton = false;

    @observable
        messages_en: MessageInterface[] = [];

    @observable
        messages_ru: MessageInterface[] = [];

    @observable
        messages_inc: MessageInterface[] = [];

    @observable
        mutedUsersList: number[] = [];

    @observable
        mutedUsersListReceived = false;

    @observable
        online: number = window.USERS_ONLINE;

    @observable
        params_en: LanguageInterface = {currentTime: 0, name: 'en'};

    @observable
        params_ru: LanguageInterface = {currentTime: 0, name: 'ru'};

    @observable
        params_inc: LanguageInterface = {currentTime: 0, name: 'inc'};

    @observable
        timerId: null | ReturnType<typeof setTimeout> = null;

    @observable
        subscription = null;

    constructor() {
        makeObservable(this);
    }

    @action
        init = (): void => {
            this.setActiveLanguages(defaultChatLang());
            this.isAvailableIncubator();
            this.setCurrentLanguage(this.activeLangs[0]);
            const langParams = this[`params_${this.currentLang}`];
            this.updateStore({ct: langParams.currentTime, lang: langParams.name, umc: false});
        };

    @action
        setMutedUsersList = (mutedList: number[]): void => {
            this.mutedUsersList = mutedList;
        };

    @action
        setTimerId = (timerId: null | ReturnType<typeof setTimeout>): void => {
            this.timerId = timerId;
        };

    @action
        setDisabledButton = (disabledButton: boolean): void => {
            this.disabledButton = disabledButton;
        };

    @action
        setMutedUsersListReceived = (received: boolean): void => {
            this.mutedUsersListReceived = received;
        };

    @action
        setLanguageParams = (language: string, params: LanguageInterface): void => {
            this[`params_${language.toLowerCase()}`] = params;
        };

    @action
        setMessages = (language: string, messages: MessageInterface[]): void => {
            this[`messages_${language.toLowerCase()}`] = messages;
        };

    @action
        setCurrentLanguage = (language: string): void => {
            this.currentLang = language;
            this.updateStoreByCurrentLanguage();
        };

    @action
        setActiveLanguages = (languages: string[]): void => {
            this.activeLangs = languages;
        };

    @action
        setOnline = (online: number): void => {
            this.online = online;
        };

    @computed
    get langs(): string[] {
        return this.activeLangs;
    }

    updateStoreByCurrentLanguage = (): void => {
        const langParams = this[`params_${this.currentLang}`];
        this.updateStore({ct: langParams.currentTime, lang: langParams.name});
    };

    getUpdateParams(newStates: UpdateParamsInterface): DataInterface {
        const states = newStates || {};
        const {params_en, params_ru} = this;

        const langParams = getLangParams({params_en, params_ru}, states.currentLang);
        const params = {
            ct: langParams.currentTime,
            lang: langParams.name,
            umc: this.mutedUsersListReceived
        };

        return params;
    }

    clearChatTimeout(disabledButton = false): void {
        clearTimeout(this.timerId);
        this.setTimerId(this.setChatTimeout());
        this.setDisabledButton(disabledButton);
    }

    setChatTimeout(): null | ReturnType<typeof setTimeout> {
        clearTimeout(this.timerId);
        const langsParams = getLangParams(this);
        return setTimeout(() => this.updateStore({ct: langsParams.currentTime, lang: langsParams.name}),
            FIVE_SECONDS);
    }

    updateMessages = (data: ResponseInterface): void => {
        const langParams = this[`params_${this.currentLang}`];
        const messages_lang = this[`messages_${this.currentLang}`].concat([]);

        const {messages, tempUpdatedAt, update} = prepareMessages(data,
            {langParams, messages: messages_lang, mutedUsersList: this.mutedUsersList});

        if (update) {
            if (tempUpdatedAt > 0) {
                this.setLanguageParams(langParams.name, {currentTime: tempUpdatedAt, name: langParams.name});
            }
            this.setMessages(langParams.name, messages);
        }
    };

    deleteMessage = (id: number): void => {
        if (this.disabledButton) {
            return;
        }

        this.setDisabledButton(true);
        const langParams = getLangParams(this);
        new Delete({params: {ct: langParams.currentTime, lang: langParams.name},
            url: `/chat/${id}`})
            .execute()
            .then(response => response.json())
            .then(response => {
                this.updateMessages(response);
                this.setTimerId(this.setChatTimeout());
                this.setDisabledButton(false);
            }).catch(() => this.clearChatTimeout(false));
    };

    isAvailableIncubator = (): void => {
        new Get({url: getLinkPath('/chat/incubator')})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.available) {
                    this.setActiveLanguages(this.langs.concat(LANGUAGES.EN_INCUBATOR));
                }
            });
    };

    sendMessage = (isRuMessage: boolean, onSuccess: () => void, messageInput: HTMLDivElement): void => {
        this.setDisabledButton(false);

        if (isRuMessage) {
            if (this.currentLang === LANGUAGES.EN_INCUBATOR) {
                toastError(I18n.t('error_incubator_chat'));
                return;
            }
            this.setCurrentLanguage(LANGUAGES.RU);
            if (this.langs.length === 1 && this.langs[0] !== LANGUAGES.RU) {
                this.setActiveLanguages(this.langs.concat(LANGUAGES.RU));
            }
        }

        const langParams = getLangParams(this);
        new Post({params: {ct: langParams.currentTime,
            lang: langParams.name,
            text: $(messageInput).text()},
        url: '/chat'})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.alert) {
                    modalAlert(response.alert);
                    this.clearChatTimeout(false);
                    return false;
                }
                $(messageInput).text('');
                this.updateMessages(response);
                this.setDisabledButton(false);
                this.setTimerId(this.setChatTimeout());
                return onSuccess();
            }).catch(() => this.clearChatTimeout(false));
    };

    userMute = (username: string, userId: number, callback?: () => void): void => {
        new Post({params: {user_id: userId}, url: '/chat/mute'})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    const newMutedUsersList = $.extend(true, {}, this.mutedUsersList);
                    newMutedUsersList[userId] = username;
                    this.setMutedUsersList(newMutedUsersList);
                    callback && callback();
                } else {
                    modalAlert(response.message);
                }
            });
    };

    removeUserMute = (userId: number, callback?: () => void): void => {
        new Delete({url: `/chat/mute/${userId}`})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    modalAlert(response.message);
                }

                const newMutedUsersList = $.extend(true, {}, this.mutedUsersList);
                delete newMutedUsersList[userId];
                this.setMutedUsersList(newMutedUsersList);
                callback && callback();
            });
    };

    updateStore = (params?: DataInterface): void => {
        new Get({params: {...params}, url: getLinkPath('/chat')})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.umc) {
                    this.setMutedUsersList(response.um);
                    this.setMutedUsersListReceived(true);
                }

                this.setOnline(response.online);
                this.setTimerId(this.setChatTimeout());
                this.updateMessages(response);
            }).catch(() => this.clearChatTimeout());
    };
}
