import React, {ReactNode} from 'react';
import PokerPlace from 'app/components/poker/poker_players/poker_places/PokerPlace';

export default class PokerPlayers extends React.Component {
    render(): ReactNode {
        return <>
            <PokerPlace position={1} isBigBlind={false}/>
            <PokerPlace position={2} isBigBlind={false}/>
            <PokerPlace position={3} isBigBlind={true}/>
            <PokerPlace position={4} isBigBlind={false}/>
            <PokerPlace position={5} isBigBlind={false}/>
            <PokerPlace position={6} isBigBlind={false}/>
            {/* <PokerPlaceFirst/>
            <PokerPlaceSecond/>
            <PokerPlaceThird/>
            <PokerPlaceFourth/>
            <PokerPlaceFifth/>
            <PokerPlaceSixth/> */}
        </>;
    }
}
