import {BLUE, GREEN, RED, lineEventName, lineGamer, WHITE} from 'app/lines/line_helpers';
import {openStream} from 'app/components/streams';
import {Table, showBetSlip} from 'app/components/table';
import CoefButton from 'app/components/table/coef_button';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import StreamInterface from 'app/interfaces/StreamInterface';
import {date as dateFormat, flagImage, gameImage, raceImage, time} from 'app/utils';
import React, {PureComponent, Fragment} from 'react';
import {gamerCoef} from 'app/lines/gamerCoef';
import {tableNick} from 'app/lines/tableNick';
import {hasUserBet} from 'app/lines/hasUserBet';
import {goToIncubatorLineEdit, goToLinkIncubator, goToLinkSimpleBets} from 'app/utils/app_links';
import {IconOpenStream} from 'app/components/pages/incubator/IconOpenStream';

interface PropsInterface {
    line: LineInterface;
    currentStream: StreamInterface;
    serial: string;
    updatedAt: number;
    editable?: boolean
}

const GAMER_1 = 1,
    GAMER_2 = 2;

export default class Row extends PureComponent<PropsInterface> {
    openStream = (e: React.MouseEvent<HTMLOrSVGElement>): void => {
        e.stopPropagation();
        openStream(this.props.currentStream);
    };

    handleCoef1Click = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        this.handleCoefClick(GAMER_1);
    };

    handleCoef2Click = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        this.handleCoefClick(GAMER_2);
    };

    handleCoefClick = (num: number): void => {
        const {editable, line} = this.props;

        if (editable) {
            goToLinkIncubator();
        } else {
            goToLinkSimpleBets();
        }
        showBetSlip(line, num, {selectedBet: line});
    };

    handleEdit = (e: React.MouseEvent): void => {
        const {line} = this.props;

        e.stopPropagation();
        goToIncubatorLineEdit(line.id);
    };

    renderGamer(num: number): React.ReactNode {
        const {line} = this.props;
        const {color} = line;
        const gamer = lineGamer(line, num);

        const coef = gamerCoef(line, num);
        const gamerNick = tableNick(line, num);

        return <div className="info-player">
            <div className="player">
                <span className="player__logo">
                    {flagImage(gamer.flag)}
                    {raceImage(gamer.race)}
                </span>
                <span className="player__name" title={gamerNick}>
                    {gamerNick}
                </span>
            </div>
            <div className="factor">
                <CoefButton
                    coef={coef}
                    className={`factor__button${hasUserBet(line, num) ? ' factor__button_has-bet' : ''}` +
                    `${color === GREEN ? ' factor__button_no-border' : ''}`}
                    onClick={num === 1 ? this.handleCoef1Click : this.handleCoef2Click}
                />
            </div>
        </div>;
    }

    colorClass = (color: number): string => {
        switch (color) {
        case GREEN:
            return 'started';
        case BLUE:
            return 'opened';
        case RED:
            return 'red';
        default:
            return '';
        }
    };

    render(): React.ReactNode {
        const {currentStream, line, editable} = this.props;
        const {date, label_type, filter, live, color, incubator, incubator_stream, esports} = line;
        const labelType = Table.labelTypes[label_type];
        return <tr className={this.colorClass(color)} >
            <td>
                <div className="info-date">
                    {live
                        ? <span className="live">{I18n.t('live_label')}</span>
                        : <Fragment>
                            <span className="date">{dateFormat(date)}</span>
                            <span className="time">{time(date)}</span>
                        </Fragment>
                    }
                </div>
            </td>
            <td>
                <div className="game">
                    <span className="status-icon">
                        {label_type !== null && <img
                            className="label-type"
                            src={labelType.src}
                            alt={I18n.t(labelType.id)}
                            title={I18n.t(labelType.id)}
                        />}
                    </span>
                    <span className="game__logo">
                        {gameImage(filter)}
                    </span>
                    <span className="game__name" title={lineEventName(line, false)}>
                        {lineEventName(line, false)}
                    </span>
                </div>
            </td>
            <td>
                {this.renderGamer(GAMER_1)}
            </td>
            <td>
                {this.renderGamer(GAMER_2)}
            </td>
            <td>
                {incubator
                    ? <IconOpenStream
                        incubator_stream={incubator_stream}
                        white={color === WHITE}
                        esports={esports}
                        bet={this.props.line}
                        className="stream-game"/>
                    : <span className="stream-game">
                        {currentStream && <svg height="14px" width="19px" onClick={this.openStream}>
                            <use xlinkHref="#svg-movie"/>
                        </svg>}
                    </span>}
            </td>
            <td>
                {editable && color !== WHITE && <span className="button-edit-incubator-line" onClick={this.handleEdit}>
                    <svg className="svg-default">
                        <use xlinkHref="#svg-edit-pencil"/>
                    </svg>
                </span>}
            </td>
        </tr>;
    }
}
