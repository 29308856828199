import {BaseController} from 'app/controllers/BaseController';
import {FilteredParamsInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import {CasinoFilterStore} from 'app/store/CasinoFilterStore';
import {CasinoGamesStore} from 'app/store/CasinoGamesStore';
import Get, {ParamsInterface} from 'app/utils/Get';
import {getLinkPath} from 'app/utils/link_helper';
import {action} from 'mobx';

interface LoadGamesParamsInterface extends ParamsInterface {
    filteredParams?: FilteredParamsInterface;
    filter_group?: number| null;
    order_by?: string;
    page?: number;
}

export class CasinoGamesController extends BaseController {
    casinoGamesStore: CasinoGamesStore;

    filterStore?: CasinoFilterStore;

    constructor(casinoGamesStore: CasinoGamesStore, filterStore?: CasinoFilterStore) {
        super();
        this.casinoGamesStore = casinoGamesStore;
        this.filterStore = filterStore;
    }

    @action
    loadGames = (params: LoadGamesParamsInterface): void => {
        new Get({
            params,
            url: '/casino/games/load_games'
        }).execute()
            .then(response => {
                response.json()
                    .then(r => {
                        this.casinoGamesStore.updateGames(r);
                    });
            });
    };

    @action
    loadFilters = (params: LoadGamesParamsInterface): void => {
        params.page = 0;

        new Get({
            params,
            url: '/casino/games/games_by_filters'
        }).execute()
            .then(response => {
                response.json()
                    .then(r => {
                        this.casinoGamesStore.updateFilters(r);
                    });
            });
    };

    @action
    init = (): void => {
        new Get({
            url: getLinkPath('/casino')
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.casinoGamesStore.init(response);
            }).finally(() => this.casinoGamesStore.changeLoadStatus(false));
    };


    @action
    loadMoreGamesByFilters = (): void => {
        if (!this.filterStore.hasMore && this.filterStore.page !== 1) {
            this.filterStore.setLoading(false);
            return;
        }

        const params = {
            filter_group: this.filterStore.filterGroupId,
            page: this.filterStore.page + 1
        };

        new Get({
            params,
            url: '/casino/games/load_games'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.casinoGamesStore
                    .updateFiltersGamesCollection(response, this.filterStore.filterGroupId);
                this.filterStore.changeHasMore(response.has_more);
                this.filterStore.changePageNumber();
            }).finally(() => this.filterStore.setLoading(false));
    };
}
