import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

export default class PokerMainPot extends React.Component {
    render(): ReactNode {
        return <div className="poker-main-pot">
            <span className="poker-pot-title">{I18n.t('poker_pot')}</span>
            <span className="poker-pot-amount">$0</span>
        </div>;
    }
}
