import React, {Component} from 'react';
import WhyEgb from '../why_egb';
import SmartStories from 'app/components/pages/home_page/SmartStories/SmartStories';
import LetsPlay from './home_page/LetsPlay';
import {newsArticles, promotionArticles, isDesktop} from 'app/utils';
import PreviewNew from 'app/components/pages/articles/home_page/PreviewNew';
import {inject, observer} from 'mobx-react';

@inject('articlesStore')
@observer
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.props.articlesStore.loadMainArticles();
    }

    render() {
        const {articlesStore} = this.props;
        return isDesktop() && <div className="home-page-wrapper">

            <SmartStories/>

            <LetsPlay/>

            <PreviewNew
                articles={newsArticles(articlesStore.homePageArticles)}
                type="news"
            />

            <PreviewNew
                articles={promotionArticles(articlesStore.homePageArticles)}
                type="promotions"
            />

            <WhyEgb/>
        </div>;
    }
}

export default HomePage;
