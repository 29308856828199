/* eslint-disable max-lines */
import {action, computed, makeObservable, observable} from 'mobx';
import {
    BetSlipFocus,
    ELEMENT_NOT_FOUND,
    FIVE_SECONDS,
    GAMER_1,
    PLAY_MONEY,
    REAL_MONEY,
    DEMO_MONEY,
    STATUS_OPENED
} from 'app/utils/Consts';
import LineInterface from 'app/interfaces/LineInterface';
import {ExpressBetTypeInterface} from 'app/interfaces/ExpressBetTypeInterface';
import {BetSlipInitParams} from 'app/interfaces/stores/BetSlipInitParams';
import {isMainLine} from 'app/lines/isMainLine';
import {isRootLine} from 'app/utils/line/rootLine';
import {incubator2WithCoef} from 'app/lines/incubator2WithCoef';
import Get from 'app/utils/Get';
import {isActiveForExpress} from 'app/lines/isActiveForExpress';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import {
    subscribeDelayedBet,
    subscribeLineUpdateCoef,
    unsubscribe
} from 'modules/WsSubscription';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {fixed3, round3} from 'app/utils/fixed';
import {isDesktop} from 'app/utils/windowFunctions';
import {isOpened} from 'app/lines/line_helpers';
import SuggestionInterface from 'app/interfaces/SuggestionInterface';
import {modalConfirm} from 'app/utils/modals/popups';
import Post from 'app/utils/Post';
import {updateInfo} from 'app/common';
import MakeBetInterface from 'app/interfaces/MakeBetInterface';
import {ONE_SECOND_MS, HALF_OF_SECOND_MS} from 'app/store/constants/storeConstants';
import {UserStore} from './UserStore';
import NotificationStore from './notifications';
import {BetsPostContractInterface} from '../components/bet_slip/BetsPost.contract';

const NOT_FOUND = 404;
export const MAX_EXPRESS_BETS = 10;

export class BetSlipStore {
    userStore: UserStore;

    @observable showForm: boolean = false;

    @observable confirm: boolean = false;

    @observable clearForm: boolean = false;

    @observable newForm: boolean = false;

    @observable minimize: boolean = false;

    @observable loadingForm: boolean = false;

    @observable loading = false;

    @observable express: boolean = false;

    @observable coefIncreased = null;

    @observable coefIncreasedForm: boolean = null;

    deleteCoefColorTimer = null;

    @observable incubator2 = false;

    @observable incubator2WithCoef: boolean | string = false;

    @observable warning: string | null = null;

    @observable limit = 0;

    @observable amount = '';

    @observable formCoef: string | null = null;

    @observable type = REAL_MONEY;

    @observable focus = 0;

    @observable netPrize = '';

    @observable win = '';

    @observable count: number = 0;

    @observable showSuggestCoef = true;

    @observable rootLine: LineInterface = null;

    @observable selectedLine: LineInterface = null;

    @observable lines: ExpressBetTypeInterface[] = [];

    @observable express_bet_max = 0;

    @observable express_playmoney_max = 0;

    @observable addedNew: boolean = false;

    @observable lastUserBets: string[] = [];

    @observable lastUserBetsPM: string[] = [];

    subscription = null;

    @observable num: number = null;

    @observable selectedLineId: string = null;

    @observable updateTime: number = 0;

    @observable delayTime: number = 0;

    @observable delayedTimerId = null;

    notificationData = null;

    delayedBetChannel = null;

    @observable statsLink: string = '';

    @observable showStatsLink: boolean = false;

    seo = false;

    timerId = null;

    clearFormTimerId = null;

    @observable userSuggestions: SuggestionInterface[] = [];

    constructor(userStore: UserStore) {
        makeObservable(this);
        this.userStore = userStore;
        if (this.userStore.user.demo) {
            this.type = DEMO_MONEY;
        }
    }

    @action
    setShowForm = (showForm: boolean): void => {
        this.showForm = showForm;
    };

    @action
    setDelayTime = (time: number): void => {
        this.delayTime = time;
    };

    @action
    setTimer = (timer: NodeJS.Timeout): void => {
        this.delayedTimerId = timer;
    };

    @action
    notifyBetWasAccepted = (data: BetsPostContractInterface): void => {
        this.notificationData = {...data.notification};

        const timer = setTimeout(() => {
            NotificationStore.add({...this.notificationData, type: 'bet_accepted'});
        }, this.delayTime * ONE_SECOND_MS + HALF_OF_SECOND_MS);

        this.setTimer(timer);
    };

    @action
    setShowSuggestCoef = (showSuggestCoef: boolean): void => {
        this.showSuggestCoef = showSuggestCoef;
    };

    @action
    setIncubator2Form = (incubator2: boolean): void => {
        this.incubator2 = incubator2;
    };

    @action
    setIncubator2WithCoef = (coef: boolean|string): void => {
        this.incubator2WithCoef = coef;
    };

    @action
    setClearForm = (clearForm: boolean, params?: MakeBetInterface): void => {
        const {line} = params ?? {};

        if (line && line.delay_for_bets_enabled) {
            this.clearFormTimerId = setTimeout(() => {
                this.setClearForm(true);
            }, this.delayTime * ONE_SECOND_MS);
        } else {
            this.clearForm = clearForm;
        }
    };

    @action
    clearAfterCancelDelayed = (): void => {
        this.clearClearFormTimer();
        this.clearDelayedTimer();
        this.setDelayTime(0);
        this.setClearForm(true);
    };

    @action
    setConfirm = (confirm: boolean): void => {
        this.confirm = confirm;
    };

    @action
    setNewForm = (newForm: boolean): void => {
        this.newForm = newForm;
    };

    @action
    setLoadingForm = (loadingForm: boolean): void => {
        this.loadingForm = loadingForm;
    };

    @action
    setMinimize = (minimize: boolean): void => {
        this.minimize = minimize;
    };

    @action
    setExpress = (express: boolean): void => {
        this.express = express;
    };

    @action
    setStatsLink = (link: string): void => {
        this.statsLink = link;
    };


    @action
    switchShowStatsLink = (show: boolean): void => {
        this.showStatsLink = show;
    };

    get showCoefInList(): boolean {
        return this.express;
    }

    get winFocus(): boolean {
        return this.focus === BetSlipFocus.WIN;
    }

    get amountFocus(): boolean {
        return this.focus === BetSlipFocus.AMOUNT;
    }

    get coefFocus(): boolean {
        return this.focus === BetSlipFocus.COEF;
    }

    get showGamerNumber(): boolean {
        return this.express;
    }

    @action
    setCoefIncreased = (coefIncreased: boolean): void => {
        this.coefIncreased = coefIncreased;
        if (coefIncreased !== null) {
            this.deleteCoefColor();
        }
    };

    @action
    setWarning = (warning: string | null): void => {
        this.warning = warning;
    };

    deleteCoefColor = (): void => {
        if (this.deleteCoefColorTimer) {
            clearTimeout(this.deleteCoefColorTimer);
        }

        this.deleteCoefColorTimer = setTimeout(() => {
            this.setCoefIncreased(null);
            this.setUpdateTime();
        }, FIVE_SECONDS);
    };

    @action
    setLimit = (limit: number): void => {
        this.limit = limit;
    };

    @action
    setFormCoef = (coef: string): void => {
        this.formCoef = coef;
    };

    @action
    setLoading = (loading: boolean): void => {
        this.loading = loading;
    };

    @action
    setType = (type: string): void => {
        this.type = type;
    };

    @action
    setAmount = (amount: string): void => {
        this.amount = amount;
    };

    @action
    setFocus = (focus: number): void => {
        this.focus = focus;
    };

    @action
    setNetPrize = (netPrize: string): void => {
        this.netPrize = netPrize;
    };

    @action
    setWin = (win: string): void => {
        this.win = win;
    };

    getCoef = (): string => String(this.formCoef);

    @action
    setCoefIncreasedForm = (coefIncreasedForm: boolean): void => {
        this.coefIncreasedForm = coefIncreasedForm;
    };

    @action
    setCount(count: number): void {
        this.count = count;
    }

    @action
    init = (params: BetSlipInitParams): void => {
        this.seo = params.seo;
        this.num = params.num;
        this.selectedLineId = params.selectedLineId;
    };

    @action
    setUpdateTime = (): void => {
        this.updateTime = Date.now();
    };

    @action
    setSelectedLine = (): void => {
        const prevCoef = this.selectedLine && this.coef;
        const selectedLine = this.findLine('hash_id', this.selectedLineId);

        if (this.selectedLine && !selectedLine) {
            this.hideLine();
            return;
        }
        this.setUpdateTime();
        this.selectedLine = selectedLine;

        if (prevCoef && this.selectedLineId) {
            this.checkCoefIncreased(Number(this.coef), Number(prevCoef));
        }
    };

    @action
    setParentLine = (selectedLine: LineInterface): void => {
        if (!selectedLine || isMainLine(selectedLine)) {
            return;
        }
        selectedLine.parent = this.findLine('id', selectedLine.game_id);

        if (!isRootLine(selectedLine.parent)) {
            this.setParentLine(selectedLine.parent);
        }
    };

    hideLine = (): void => {
        this.selectedLine = null;
        this.setIncubator2Form(false);
        this.setShowForm(false);
    };

    findLine = (field: string, value: string|number): LineInterface => {
        if (this.rootLine[field] === value) {
            return this.rootLine;
        }

        const {nested_bets} = this.rootLine;
        return nested_bets[Object.keys(nested_bets).find(
            key => nested_bets[key][field] === value
        )];
    };

    get coef(): string {
        if (this.selectedLine.incubator_2_bet) {
            return fixed3(incubator2WithCoef(this.selectedLine, this.num) as string);
        }
        if (this.coefFocus) {
            return this.formCoef;
        }
        return fixed3(this.num === GAMER_1 ? this.selectedLine.coef_1 : this.selectedLine.coef_2);
    }

    clear = (): void => {
        this.clearUpdateTimer();
        this.unsubscribeChannel();
    };

    @action
    reset = (): void => {
        this.rootLine = null;
        this.selectedLine = null;
        this.selectedLineId = null;
        this.lines = [];
        this.num = 0;
    };

    clearUpdateTimer = (): void => {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    };

    clearDelayedTimer = (): void => {
        if (this.delayedTimerId) {
            clearTimeout(this.delayedTimerId);
            this.delayedTimerId = null;
        }
    };

    clearClearFormTimer = (): void => {
        if (this.clearFormTimerId) {
            clearTimeout(this.clearFormTimerId);
            this.clearFormTimerId = null;
        }
    };

    restartUpdate = (): void => {
        this.clearUpdateTimer();
        this.updateFormBet();
    };

    willUpdateBetForm = (): void => {
        this.timerId = setTimeout(() => this.updateFormBet(), FIVE_SECONDS);
    };

    activeLine = (id: number): ExpressBetTypeInterface => {
        const {num, selectedLine, rootLine, lines} = this;
        const line = lines?.find(l => l.id === id);

        if (this.lines?.length > 0) {
            if (line) {
                return {
                    id: line.id,
                    line: line.line,
                    on: line.on,
                    root_line_id: line.root_line_id
                };
            }
        }

        if (this.lines?.length === 0 && selectedLine && selectedLine.id === id) {
            return {id: selectedLine.id,
                line: selectedLine,
                on: num,
                root_line_id: rootLine && rootLine.id};
        }

        return null;
    };

    @action
    setIncubator2 = (line: LineInterface): void => {
        if (line.incubator_2_bet) {
            if (this.showSuggestCoef) {
                this.setIncubator2WithCoef(incubator2WithCoef(line, this.num));
            }
        } else if (this.incubator2WithCoef) {
            this.setIncubator2Form(false);
            this.setIncubator2WithCoef(null);
        }
    };

    @action
    updateFormBet = (openForm = false, hashId = null, selectedLineId = null): void => {
        if (!hashId && !this.rootLine || this.isExpress()) {
            return;
        }
        new Get({url: `/bets/${hashId || this.rootLine.hash_id}`})
            .execute()
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(data => {
                this.setNewForm(openForm);
                this.updateLine(data.bet);
                this.setIncubator2(this.selectedLine);
                this.setUserSuggestion(this.selectedLine.user_suggestions || []);

                if (isActiveForExpress(this.selectedLine)) {
                    this.addOrUpdateExpressBet(this.selectedLine, this.num);
                }
                if (openForm) {
                    this.listen();
                }
                this.willUpdateBetForm();
            })
            .finally(() => {
                if (openForm && this.selectedLine && this.selectedLine.hash_id === selectedLineId) {
                    this.setLoadingForm(false);
                }
            })
            .catch((response: Response) => {
                if (response.status === NOT_FOUND) {
                    this.removeSameLineIfAny(this.selectedLine);
                    toastError(I18n.t('line_na'));
                    this.hideLine();
                } else {
                    this.willUpdateBetForm();
                }
            });
    };

    @action
    resetFormBet = (): void => {
        this.clearUpdateTimer();
        this.updateFormBet();
    };

    @action
    getFormBet = (rootLineId: string, selectedLineId: string, num: number): void => {
        this.clear();
        if (this.checkCloseForm(selectedLineId, num)) {
            return;
        }
        this.reset();
        this.setInitParamsForm();
        this.init({num, selectedLineId, seo: false});
        this.setExpress(false);
        this.updateFormBet(true, rootLineId, selectedLineId);
    };

    setInitParamsForm = (): void => {
        this.setMinimize(false);
        this.setExpress(false);
        this.setIncubator2Form(false);
        this.setShowForm(true);
        this.setLoadingForm(true);
        this.setShowSuggestCoef(true);
    };

    checkCloseForm = (selectedLineId: string, num: number): boolean => {
        if (this.showForm === true && this.selectedLineId === selectedLineId && this.num === num) {
            this.setShowForm(false);
            this.reset();
            return true;
        }
        return false;
    };

    @action
    updateLine(newLine: LineInterface): void {
        this.rootLine = newLine;
        this.setSelectedLine();
        this.setParentLine(this.selectedLine);
    }

    @action
    updateLines(newParentLines: LineInterface[]): void {
        this.lines.forEach(expressBet => {
            const newLine = newParentLines.find(line => line.id === expressBet.id);

            if (newLine) {
                expressBet.line = newLine;
                this.setUpdateTime();
            }
        });
    }

    updateLineById(line: LineInterface, newLine: LineInterface): boolean {
        let result = false;
        ['nested_bets', 'advantages'].forEach(key => {
            if (!Array.isArray(line[key])) {
                return;
            }

            const nestedLineIndex = line[key].findIndex(nestedLine => nestedLine.id === newLine.id);

            if (nestedLineIndex === ELEMENT_NOT_FOUND) {
                line[key].forEach(nestedLine => {
                    if (this.updateLineById(nestedLine, newLine)) {
                        result = true;
                    }
                });
            } else {
                line[key][nestedLineIndex] = {
                    ...line[key][nestedLineIndex],
                    ...newLine
                };
                result = true;
            }
            if (result) {
                line[key] = [...line[key]];
            }
        });
        return result;
    }

    checkCoefIncreased = (coef: number, prevCoef: number): void => {
        if (coef !== prevCoef) {
            this.setCoefIncreased(coef > prevCoef);
        }
    };

    @action
    checkCoefIncreasedExpress = (prevCoef: number): void => {
        const coef = Number(this.expressCoef);

        if (prevCoef && coef && prevCoef !== coef) {
            this.setCoefIncreased(coef > prevCoef);
            this.removeClosedLines(true);
        }
    };

    @action
    updateCoefs(line: LineInterface): void {
        if (this.rootLine && this.rootLine.id === line.id) {
            this.rootLine = {
                ...this.rootLine,
                ...line
            };
            this.setSelectedLine();
            this.setParentLine(this.selectedLine);
            this.setFormCoef(this.coef);
            return;
        }

        if (this.rootLine && this.updateLineById(this.rootLine, line)) {
            this.rootLine = {...this.rootLine};
            this.setSelectedLine();
            this.setParentLine(this.selectedLine);
            this.setFormCoef(this.coef);
        }
    }

    listen(): void {
        this.subscription = subscribeLineUpdateCoef(this.rootLine.hash_id, ({data: {event, payload}}) => {
            if (event === 'update_coef') {
                this.updateCoefs(payload.line);
            }
        });

        if (this.selectedLine.delay_for_bets_enabled) {
            if (this.delayedBetChannel) {
                return;
            }
            this.delayedBetChannel = subscribeDelayedBet(this.userStore.user.id, ({data: {event}}) => {
                if (event === 'cancelled') {
                    this.clearAfterCancelDelayed();
                }
                if (event === 'accepted') {
                    this.clearAfterCancelDelayed();
                    NotificationStore.add({...this.notificationData, type: 'bet_accepted'});
                }
            });
        }
    }

    @action
    unsubscribeChannel = (): void => {
        this.subscription = unsubscribe(this.subscription);
    };

    isExpress = (): boolean => this.lines && this.lines.length > 1;

    getExpressLimit = (type: string): number => isPlayMoney(type) ? this.express_playmoney_max : this.express_bet_max;

    @action
    setAddedNew = (addedNew: boolean): void => {
        this.addedNew = addedNew;
    };

    @action
    updateExpressMax = (express_bet_max: number): void => {
        this.express_bet_max = express_bet_max;
    };

    @action
    updateExpressPlaymoneyMax = (express_playmoney_max: number): void => {
        this.express_playmoney_max = express_playmoney_max;
    };

    @action
    addExpressBet = (line: LineInterface, on: number): void => {
        const {id, root_line_id} = line;
        this.lines.push({
            id,
            line,
            on,
            root_line_id
        });

        if (this.isExpress()) {
            this.clear();
            this.setAddedNew(true);
            this.updateForm();
        }
    };

    @action
    addOrUpdateExpressBet = (line: LineInterface, on: number): void => {
        if (this.lines.find(bet => bet.root_line_id === line.root_line_id)) {
            this.lines.splice(0, 1);
        }

        this.addExpressBet(line, on);
    };

    @computed
    get expressBetsIdOn(): {number?: number} {
        const res: {number?: number} = {};
        this.lines.forEach(express => {
            res[express.id] = express.on;
        });
        return res;
    }

    get isEsport(): boolean {
        return this.lines[0] && this.lines[0].line.esports;
    }

    @action
            // eslint-disable-next-line max-statements
    removeSameLineIfAny = (line: LineInterface, adding = false): void => {
        if (this.lines.find(bet => bet.root_line_id === line.root_line_id)) {
            this.lines = this.lines.filter(bet => bet.root_line_id !== line.root_line_id);

            if (this.lines.length < 1) {
                this.setShowForm(false);
            }

            if (this.isExpress() || adding) {
                this.getExpressData();
            } else {
                this.setExpress(false);
                if (this.lines.length > 0) {
                    this.init({num: this.lines[0].on, selectedLineId: this.lines[0].line.hash_id, seo: false});
                    this.updateFormBet(true, this.lines[0].root_line_id, this.lines[0].id);
                }
            }
        }
    };

    @computed
    get expressCoef(): string {
        return round3(this.lines.reduce(
            (acc, expressBet) => acc * expressBet.line[`coef_${expressBet.on}`],
            1
        ));
    }

    checkChangedCoef = (prevCoef: number, prevCount: number): boolean => {
        const count = this.lines.length;

        if (prevCount === count) {
            if (this.addedNew) {
                this.setAddedNew(false);
            } else {
                this.checkCoefIncreasedExpress(prevCoef);
            }
            return true;
        }
        return false;
    };

    @action
    changeSelectedTeam = (id: number, on: number): void => {
        const bet = this.lines.find(item => item.id === id);

        if (bet) {
            bet.on = on;
        }
    };

    @action
    replaceLine = (line: ExpressBetTypeInterface): void => {
        if (line.line.ee) {
            this.lines.splice(0, 1, line);
            this.init({num: this.lines[0].on, selectedLineId: this.lines[0].line.hash_id, seo: false});
            this.updateFormBet(true, this.lines[0].root_line_id, this.lines[0].id);
        } else {
            this.lines.splice(0, 1);
            this.init({num: line.on, selectedLineId: line.line.hash_id, seo: false});
            this.updateFormBet(true, line.root_line_id, line.id);
        }
    };

    // eslint-disable-next-line max-statements
    coefClick = (line: LineInterface, on: number): void => {
        if (line.live) {
            toastError(I18n.t('live_is_not_avaiable'));
            return;
        }

        const has_root_line = this.lines.find(bet => bet.root_line_id === line.root_line_id);

        if (!isActiveForExpress(line) &&
                (this.lines.length >= 1 && !has_root_line || this.lines.length > 1 && has_root_line)) {
            toastError(I18n.t('line_is_not_avaiable_to_express', {name: line.game_label}));
            return;
        }

        const existingBet = this.lines.find(bet => bet.id === line.id);

        if (existingBet && existingBet.on === on) {
            this.removeSameLineIfAny(line);
        } else if (existingBet && this.lines.length === 1) {
            this.changeSelectedTeam(line.id, on);
            this.init({num: on, selectedLineId: this.selectedLineId, seo: false});
            this.setMinimize(false);
        } else if (has_root_line && this.lines.length === 1) {
            this.replaceLine({id: line.id, line, on, root_line_id: line.root_line_id});
        } else {
            this.removeSameLineIfAny(line, true);
            if (this.lines.length + 1 > MAX_EXPRESS_BETS) {
                toastError(I18n.t('place_more_than_ten_express_bets_message'));
                return;
            }
            if (line.status !== STATUS_OPENED) {
                return;
            }
            if (!isDesktop()) {
                this.setMinimize(true);
            }
            this.addExpressBet(line, on);
        }
    };

    @action
    removeClosedLines = (update = false): void => {
        if (this.lines) {
            const res = this.lines.filter(bet => isOpened(bet.line.color));

            if (update || res.length !== this.lines.length) {
                this.lines = res;

                if (this.lines.length === 1) {
                    this.setExpress(false);
                    this.init({num: this.lines[0].on, selectedLineId: this.lines[0].line.hash_id, seo: false});
                    this.updateFormBet(true, this.lines[0].root_line_id, this.lines[0].id);
                } else if (this.lines.length === 0) {
                    this.setExpress(false);
                    this.updateFormBet(true, this.rootLine.id, this.selectedLineId);
                }
            }
        }
    };

    updateForm = (): void => {
        this.setShowForm(true);
        this.setExpress(true);
        this.getExpressData();
    };

    getExpressData = (): void => {
        this.setLoadingForm(true);
        new Get({
            params: {list: this.expressBetsIdOn},
            url: '/express'
        }).execute()
            .then(data => data.json())
            .then(data => {
                if (data) {
                    this.updateExpressMax(data.express_bet_max);
                    this.updateExpressPlaymoneyMax(data.express_playmoney_max);
                    if (this.type === REAL_MONEY && this.express_bet_max < Number(this.amount)) {
                        this.setAmount(String(this.express_bet_max));
                    }

                    if (this.type === PLAY_MONEY && this.express_playmoney_max < Number(this.amount)) {
                        this.setAmount(String(this.express_playmoney_max));
                    }
                }
            })
            .finally(() => this.setLoadingForm(false));
    };

    @computed
    get incubator(): boolean {
        return this.selectedLine?.incubator;
    }

    @action
    setUserSuggestion = (userSuggestions: SuggestionInterface[]): void => {
        this.userSuggestions = userSuggestions;
    };

    @computed
    get userSuggestionByEvent(): SuggestionInterface[] {
        return this.userSuggestions.filter(suggestion => suggestion.event === this.num);
    }

    cancelSuggestion = (ids: number[]): void => {
        modalConfirm(I18n.t('cancel_suggestion'), () => {
            new Post({
                params: {ids},
                url: '/incubator2/bets/cancel'
            }).execute()
                .then(response => {
                    if (response.ok) {
                        toastSuccess(I18n.t('suggestion_cancelled'));
                        updateInfo();
                    }
                });
        });
    };
}
