import {addGaEvent} from 'app/ga_methods';
import GameInterface from 'app/interfaces/GameInterface';
import LinesService, {Lines} from 'app/lines/LinesService';
import {StreamStore} from 'app/store/stream_store';
import {deleteObjectBy} from 'app/utils/updateArraysObject';
import {BET_TYPES, DEFAULT_EVENTS, DISCIPLINES, INCUBATOR_BET_TYPE} from 'app/store';
import {action, computed, makeObservable, observable} from 'mobx';
import {getAllFiltersFlag} from 'app/utils';
import Cookies from 'js-cookie';
import LineInterface from 'app/interfaces/LineInterface';
import TableUpdateInterface from 'app/interfaces/TableUpdateInterface';
import EventInterface from 'app/interfaces/EventInterface';
import {linesDataForUpdate} from 'app/lines/linesDataForUpdate';
import {isActiveLine} from 'app/lines/isActiveLine';
import {GamesStore} from 'app/store/GamesStore';
import {BetSlipStore} from 'app/store/BetSlipStore';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import {DefaultSettingsInterface, TableStoreConstructor} from 'app/interfaces/TableStoreConstructorInterface';
import {COOKIES_EXPIRATION_TIME, TIME_OUT} from 'app/store/constants/storeConstants';
import {FilterStore} from 'app/store/filters/FilterStore';
import {UserStore} from 'app/store/UserStore';

export class TableStore {
    linesService: LinesService;

    streamStore: StreamStore;

    betSlipStore: BetSlipStore;

    gamesStore: GamesStore;

    filterStore: FilterStore;

    userStore: UserStore;

    filterType: string;

    constructor({linesService, streamStore, betSlipStore, gamesStore, filterStore, userStore}: TableStoreConstructor) {
        this.filterStore = filterStore;
        this.linesService = linesService;
        this.streamStore = streamStore;
        this.betSlipStore = betSlipStore;
        this.gamesStore = gamesStore;
        this.filterType = this.filterStore.filterType;
        this.initSettings();
        this.setFilterResult();
        this.linesService.tableStore = this;
        this.userStore = userStore;
        makeObservable(this);
    }

    initSettings(): void {
        this.showAllFilters = getAllFiltersFlag(this.filterStore.filters);
    }

    setFilterResult(): void {
        this.showAllFiltersResults = getAllFiltersFlag(this.filterStore.filters);
    }

    timerId: null | ReturnType<typeof setTimeout>;

    @observable bets = [];

    @observable resultsBets = [];

    @observable showAllFilters: boolean = false;

    @observable showAllFiltersResults: boolean = false;

    @observable showMoreFilter = false;

    @observable tableCompact = typeof Cookies.get('tableCompact') === 'undefined'
        ? !window.settings.user.lite
        : JSON.parse(Cookies.get('tableCompact'));

    @observable esports = true;

    @observable results = false;

    @observable disciplines: CollectionInterface[] = DISCIPLINES;

    @observable betTypes: CollectionInterface[] = BET_TYPES;

    @observable incubatorBetType: CollectionInterface[] = INCUBATOR_BET_TYPE;

    openedLines = [];

    loadedLines = [];

    subscription = null;

    startedUpdateLines = false;

    startedUpdateLinesResults = false;

    defaultBetType = 'all';

    @computed
    get incubatorCount(): number {
        return this.bets.filter(b => b.incubator && isActiveLine(b)).length;
    }

    @computed
    get sportLines(): LineInterface[] {
        return this.currentBets().filter(b => !b.esports);
    }

    @computed
    get egamingLines(): LineInterface[] {
        return this.currentBets().filter(b => b.esports);
    }

    @computed
    get allLines(): LineInterface[] {
        const {discipline, betType} = this.filterStore;

        if (discipline === 'esport') {
            switch (betType) {
            case 'live':
                return this.egamingLines.filter(b => b.live);
            case 'prematch':
                return this.egamingLines.filter(b => !b.live);
            default:
                return this.egamingLines;
            }
        }
        if (discipline === 'sport') {
            switch (betType) {
            case 'live':
                return this.sportLines.filter(b => b.live);
            case 'prematch':
                return this.sportLines.filter(b => !b.live);
            default:
                return this.sportLines;
            }
        }
        if (discipline === 'all' || !discipline || discipline.trim().length === 0) {
            switch (betType) {
            case 'live':
                return this.currentBets().filter(b => b.live);
            case 'prematch':
                return this.currentBets().filter(b => !b.live);
            default:
                return this.currentBets();
            }
        } else {
            return this.currentBets();
        }
    }

    @computed
    get lines(): LineInterface[] {
        return this.esports ? this.egamingLines : this.sportLines;
    }

    @computed
    get liveLines(): LineInterface[] {
        return (this.esports ? this.sportLines : this.egamingLines).filter(b => b.live);
    }

    findBetById = (id: number): LineInterface => this.bets.find(bet => bet.id === id);

    getBetTypes = (): CollectionInterface[] => this.betTypes;

    getIncubatorBetType = (): CollectionInterface[] => this.incubatorBetType;

    currentBets = (): LineInterface[] => this.results ? this.resultsBets : this.bets;

    getShowAllFilters = (): boolean => this.results ? this.showAllFiltersResults : this.showAllFilters;

    areEventsChanged = (events: EventInterface[]): boolean => {
        const currentEvents = this.filterStore.getEvents();
        return currentEvents.length === 0 || JSON.stringify(events) !== JSON.stringify(currentEvents);
    };

    @action update(data: TableUpdateInterface): void {
        Object.keys(data).forEach(key => {
            if (typeof this[key] !== 'undefined' && typeof data[key] !== 'undefined') {
                this[key] = data[key];
            }
        });
    }

    @action loadLine(id: number | number[]): Promise<void> {
        return this.linesService.update_line(id, this.updateBetsCallback, this.results);
    }

    @action
    applyTableFilter(filter_id: number,
        currentEvents: EventInterface[],
        options = {forceUseHidden: false, results: false}): void {
        const {forceUseHidden = false} = options;
        const newFilters = this.filterStore.filters.slice();
        const filter: GameInterface = newFilters.find(f => f.id === filter_id);

        if (!filter || !forceUseHidden && filter.hide) {
            return;
        }
        filter.val = !filter.val;
        if (filter.id === 0) {
            newFilters.forEach(hiddenFilter => {
                if (hiddenFilter.hide) {
                    hiddenFilter.val = filter.val;
                }
            });
        }
        this.filterStore.updateFilters(currentEvents, newFilters);
        this.updateFilter(newFilters, {});
    }

    updateFiltersPage(): void {
        this.update({
            filtersWithSport: this.filterStore.filters,
            filtersWithSportResults: this.filterStore.filters,
            showAllFilters: getAllFiltersFlag(this.filterStore.filters),
            showAllFiltersResults: getAllFiltersFlag(this.filterStore.filters)
        });
    }

    updateFilter = (filters: GameInterface[], newStates = {}): void => {
        if (this.results) {
            this.update({
                ...newStates,
                filtersResults: filters,
                showAllFiltersResults: getAllFiltersFlag(filters)
            });
        } else {
            this.update({
                ...newStates,
                filters,
                showAllFilters: getAllFiltersFlag(filters)
            });
        }
    };

    updateBetsCallback = (parents: Lines, results: boolean): void => {
        const linesData = linesDataForUpdate(parents, results);

        if (this.areEventsChanged(linesData?.events)) {
            this.update(results
                ? {resultsBets: linesData.bets}
                : {bets: linesData.bets});
            this.filterStore.update({events: linesData.events});
        } else {
            this.update(results ? {resultsBets: linesData.bets} : {bets: linesData.bets});
        }
        this.betSlipStore.updateLines(linesData.bets);
        this.betSlipStore.removeClosedLines();
    };

    startUpdateLines(): void {
        if (!this.startedUpdateLines) {
            this.updateBets();
            this.startedUpdateLines = true;
        }
    }

    stopUpdateLines(): void {
        if (this.startedUpdateLines) {
            clearTimeout(this.timerId);
            this.startedUpdateLines = false;
        }
    }

    startUpdateLinesResults(): void {
        if (!this.startedUpdateLinesResults) {
            this.updateBets(true, true);
            this.startedUpdateLinesResults = true;
        }
    }

    addOpenedLineId(id: number): void {
        if (!this.openedLines.find(i => i === id)) {
            this.openedLines.push(id);
        }
    }

    hasOpenedLineId(id: number): boolean {
        return Boolean(this.openedLines.find(i => i === id));
    }

    deleteOpenedLineId(id: number): void {
        deleteObjectBy(this, 'openedLines', i => i === id);
    }

    addLoadedLineId(id: number | number[]): void {
        (Array.isArray(id) ? id : [id]).forEach(newId => {
            if (!this.loadedLines.find(i => i === newId)) {
                this.loadedLines.push(newId);
            }
        });
    }

    hasLoadedLineId(id: number): boolean {
        return Boolean(this.loadedLines.find(i => i === id));
    }

    clearLoadedLinesIds(): void {
        this.loadedLines = [];
    }

    removeLoadedLine(lineId: number): void {
        deleteObjectBy(this, 'loadedLines', i => i === lineId);
    }

    reloadLines = (): void => {
        this.linesService.update_bets(this.updateBetsCallback, this.results, true).catch();
    };

    updateBets = (results = false, allLines = false): void => {
        this.linesService
            .update_bets(this.updateBetsCallback, results, allLines)
            .finally(() => {
                this.timerId = setTimeout(() => {
                    this.updateBets(results);
                }, TIME_OUT);
            });
    };

    setCurrentEvent = (currentEvents: EventInterface[], results = false): void => {
        this.update(results ? {currentEventsResults: currentEvents} : {currentEvents});
        Cookies.set(`${this.filterStore.filterType}CurrentEvents`, currentEvents, {expires: COOKIES_EXPIRATION_TIME});
        this.filterStore.update({currentEvents});
        this.filterStore.updateNavigateUrl();
    };

    setDefaults = (): DefaultSettingsInterface => ({
        currentEventsResults: DEFAULT_EVENTS,
        only24hResults: false,
        onlyUpcomingMatchesResults: false
    });

    @action
    clearFilters = (): void => {
        const filters = this.filterStore.currentFilters().slice().filter(f => f.val === true);
        this.filterStore.changeFiltersVal(filters);
        this.streamStore.setCurrentEvents(DEFAULT_EVENTS);
        this.filterStore.update({betType: this.defaultBetType,
            currentEvents: DEFAULT_EVENTS,
            discipline: 'all',
            only24h: false,
            onlyUpcomingMatches: false});
        this.setDefaultCookies();
        this.filterStore.updateNavigateUrl();
        this.updateFiltersPage();
        addGaEvent(this.results ? 'FiltersResults' : 'Filters', 'clear');
    };

    setDefaultCookies = (): void => {
        Cookies.set(`${this.filterStore.filterType}Disc`, 'all');
        Cookies.set(`${this.filterStore.filterType}Type`, 'all');
        Cookies.set(`${this.filterStore.filterType}24h`, false);
        Cookies.set(`${this.filterStore.filterType}Up`, false);
        Cookies.set(`${this.filterStore.filterType}CurrentEvents`, DEFAULT_EVENTS);
        Cookies.set(`${this.filterStore.filterType}Filters`, DEFAULT_EVENTS);
    };
}
