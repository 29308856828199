import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

export default class PokerMenuButtonQuit extends Component {
    render(): ReactNode {
        return <button className="poker-menu-container-button quit">
            <svg className="svg-default"><use xlinkHref="#svg-quit-icon"/></svg>
            <span className="name">{I18n.t('poker_bet_exit_table')}</span>
        </button>;
    }
}
