import {userStore} from 'app/store/global';

const empty = (): void => null;

const SiteVersion = {
    Aside: null,
    HeaderMenu: [],
    HeaderMobileMenu: [],
    PlayNowList: [],
    Sitemap: null,
    headerLootbox: null,
    logoLink: null,
    profileMenu: null,
    streamsListMobile: null,
    userArtifacts: null,
    userBalancePoints: null,
    userNav: null,
    userNavBottom: null,
    userPromotions: null,
    volumeControl: null
};

const liteComponents = {
    Aside: () => import('site_version/lite/Aside'),
    HeaderMenu: () => import('site_version/lite/HeaderMenu'),
    HeaderMobileMenu: () => import('site_version/lite/HeaderMobileMenu'),
    PlayNowList: () => import('site_version/lite/PlayNowList'),
    Sitemap: () => import('site_version/lite/Sitemap'),
    headerLootbox: () => import('site_version/lite/headerLootbox'),
    logoLink: () => import('site_version/lite/logoLink'),
    profileMenu: () => import('site_version/lite/profileMenu'),
    streamsListMobile: () => import('site_version/lite/streamsListMobile'),
    userArtifacts: () => import('site_version/lite/userArtifacts'),
    userBalancePoints: () => import('site_version/lite/userBalancePoints'),
    userNav: () => import('site_version/lite/UserNav'),
    userNavBottom: () => import('site_version/lite/userNavBottom'),
    userPromotions: () => import('site_version/lite/userPromotions'),
    volumeControl: () => import('site_version/lite/volumeControl')
};

const comComponents = {
    Aside: () => import('site_version/com/Aside'),
    HeaderMenu: () => import('site_version/com/HeaderMenu'),
    HeaderMobileMenu: () => import('site_version/com/HeaderMobileMenu'),
    PlayNowList: () => import('site_version/com/PlayNowList'),
    Sitemap: () => import('site_version/com/Sitemap'),
    headerLootbox: () => import('site_version/com/headerLootbox'),
    logoLink: () => import('site_version/com/logoLink'),
    profileMenu: () => import('site_version/com/profileMenu'),
    streamsListMobile: () => import('site_version/com/streamsListMobile'),
    userArtifacts: () => import('site_version/com/userArtifacts'),
    userBalancePoints: () => import('site_version/com/userBalancePoints'),
    userNav: () => import('site_version/com/UserNav'),
    userNavBottom: () => import('site_version/com/userNavBottom'),
    userPromotions: () => import('site_version/com/userPromotions'),
    volumeControl: () => import('site_version/com/volumeControl')
};
export default SiteVersion;

export const importSiteVersion = async(): Promise<void> => {
    const components = userStore.user.lite ? liteComponents : comComponents;
    await Promise.all(Object.entries(components).map(async([, component]) => {
        await component();
    }));
};
