import amplitudeJS from 'amplitude-js';
import Cookies from 'js-cookie';
import {isDesktop} from 'app/utils';
import {userStore} from 'app/store/global';

const AMPLITUDE_DEVICE_ID_KEY = 'amplitude_device_id';

const setDeviceId = amplitude => {
    const amplitudeDeviceId = localStorage.getItem(AMPLITUDE_DEVICE_ID_KEY);

    if (amplitudeDeviceId) {
        amplitude.setDeviceId(amplitudeDeviceId);
    } else {
        localStorage.setItem(AMPLITUDE_DEVICE_ID_KEY, amplitude.options.deviceId);
    }
};

const platform = () => {
    if (window.SITE_DATA.MOBILE_WRAPPER) {
        if (Cookies.get('android')) {
            return 'Wrapper Android';
        }
        if (Cookies.get('ios')) {
            return 'Wrapper iOS';
        }
        return 'Wrapper';
    }
    return isDesktop() ? 'Desktop Web' : 'Mobile Web';
};

const userProperties = () => {
    const {
        country,
        verified_status,
        balance,
        site_lang,
        name,
        lite
    } = userStore.user;

    return {
        APP_LANGUAGE: site_lang,
        NICKNAME: name,
        PLATFORM: platform(),
        REGISTRATION_COUNTRY: country,
        SITE: window.location.hostname,
        SITE_VERSION: lite ? 'LITE' : 'PRO',
        USER_BALANCE: balance,
        USER_STATUS: verified_status
    };
};

const amplitude = amplitudeJS.getInstance();

export const amplitudeInit = () => {
    amplitude.init(window.AMPLITUDE_API_KEY, window.settings.user.id);
    setDeviceId(amplitude);
    amplitude.setUserProperties(userProperties());
};

export default amplitude;
