import $ from 'jquery';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {i18n_t} from 'app/i18n';
import {getLinkPath, scrollToTop, serverError, slowConnection, userSignedIn} from 'app/utils';
import doubleArrDownSvg from 'images/svg_icons/double-arr-down.svg';
import SliderContainer from 'app/components/slider_container';

interface HiddenTempleState {
    error: any;
    opened: boolean;
    question: string;
}

interface WindowHiddenTempleNews extends Window {
    HIDDEN_TEMPLE_NEWS: any;
}
declare var window: WindowHiddenTempleNews;

export default class HiddenTemple extends Component<{}, HiddenTempleState> {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            opened: false,
            question: ''
        };
        this.toggleOpener = this.toggleOpener.bind(this);
        this.handlerChangeQuestion = this.handlerChangeQuestion.bind(this);
    }

    successfulMessage: React.RefObject<HTMLDivElement> = React.createRef();

    handlerSendQuestion = e => {
        e.preventDefault();
        slowConnection($.ajax('/hidden_temples', {
            data: {hidden_temple: {question: this.state.question}},
            statusCode: {
                200: () => {
                    this.setState({
                        error: null,
                        question: ''
                    });
                    const TWO_SECONDS = 2000;
                    setTimeout(() => this.setState({opened: false}), TWO_SECONDS);
                },
                401: error => this.setState({error: error.responseJSON.message}),
                422: error => this.setState({error: [...Object.values(error.responseJSON)][0]}),
                500: serverError
            },
            type: 'POST'
        }));
    };

    toggleOpener() {
        this.setState({opened: !this.state.opened});
    }

    handlerChangeQuestion(e) {
        this.setState({
            error: null,
            question: e.target.value
        });
    }

    handleHiddenTempleClick = e => e.stopPropagation();

    render() {
        const {error, opened, question} = this.state;
        return <div className="chat chat_hidden_temple">
            <div className="chat__heading cursor-pointer" onClick={this.toggleOpener}>
                <div className="chat__title">
                    <div className="aside-preview-post__title">
                        <Link
                            to={getLinkPath('/news/hidden_temples')}
                            onClick={scrollToTop}
                        >
                            Hidden temple
                        </Link>
                        <div className="faq-button">
                            <Link
                                to={getLinkPath('/hidden_temple')}
                                target="_blank"
                                className="act faq-button__icon"
                                onClick={this.handleHiddenTempleClick}
                            >
                                <svg height="20px" width="20px">
                                    <use xlinkHref="#svg-question"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="chat__controls" style={{width: 0}}>
                    <button className={`act chat__opener ${opened ? 'opener-active' : ''}`}>
                        <img src={doubleArrDownSvg} alt="opener" height="12px" width="12px"/>
                    </button>
                </div>
            </div>
            <SliderContainer opened={opened}>
                <div className="live-bets__list">
                    {userSignedIn()
                        ? <form className="popup-form--error">
                            <div className="popup-form__error-hint">{error}</div>
                            <div style={{display: 'none'}} ref={this.successfulMessage}>
                                {i18n_t('user.successful_hidden_temple_message')}
                            </div>
                            <div className={`field ${error ? 'field-error' : ''}`}>
                                <textarea
                                    maxLength={1024}
                                    placeholder={i18n_t('user.your_questions')}
                                    value={question}
                                    onChange={this.handlerChangeQuestion}
                                    className="field__area"
                                />
                            </div>
                            <div className="popup-form__submit-row" style={{paddingTop: 0}}>
                                <button
                                    className="button btn"
                                    onClick={this.handlerSendQuestion}
                                >
                                    {i18n_t('user.send')}
                                </button>
                            </div>
                        </form>
                        : null }
                    {window.HIDDEN_TEMPLE_NEWS.map((news, i) => <div
                        className="chat__message"
                        style={{padding: '5px 0 5px 0px'}} key={i}
                    >
                        <Link
                            to={getLinkPath(`/news/${news.slug}`)}
                            key={i}
                            onClick={scrollToTop}
                        >
                            {news.title}
                        </Link>
                    </div>)}
                </div>
            </SliderContainer>
        </div>;
    }
}
