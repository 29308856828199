import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {date, gameImage, getLinkPath, time} from 'app/utils';
import SiteVersion from 'site_version/SiteVersion';
import {SignUpButton} from '../auth/log_in_sign_up_button';
import heroTablet from 'images/matches/man-tablet.png';
import heroMobile from 'images/matches/man-mobile.png';
import GamerPhoto from 'app/components/bet_form/GamerPhoto';
import {fixed3} from 'app/utils/fixed';
import {linkAndroid, linkIOSDownload, linkLine} from 'app/utils/app_links';
import {Link} from 'react-router-dom';
import logEvent from 'app/amplitude/log_event';
import {openRegistration} from 'app/components/auth/auth';

const Bonus = () => <div className="advertising__left">
    <div className="get_bonus-wrap">
        <div className="get_bonus">
            <h1 className="get_bonus__title">
                <span className="get_bonus__title_1">
                    {I18n.t('advertising.title1')}
                </span>
                <span className="get_bonus__title_2">
                    {I18n.t('advertising.title2')}
                </span>
                <span className="get_bonus__title_3">
                    {I18n.t('advertising.title3')}
                </span>
            </h1>
        </div>
    </div>
    <div className="advertising__hero-wrap">
        <div className="advertising__hero">
            <picture>
                <source srcSet={heroTablet} media="(min-width: 768px)"/>
                <img
                    src={heroMobile}
                    alt={`${I18n.t('advertising.title1')} ${I18n.t('advertising.title2')} ` +
                            `${I18n.t('advertising.title3')} ${I18n.t('advertising.up_to')} $600!`}
                />
            </picture>
        </div>
        <div className="up_to">
            {I18n.t('advertising.up_to')} $600!
        </div>
    </div>
</div>;

const TeamName = ({coef, gamer}) => <div className="team-name-rate">
    <div className="team-name">{gamer.nick}</div>
    <div className="team-rate">{fixed3(coef)}</div>
</div>;

const TeamLogo = ({gamer}) => <div className="team-logo"><GamerPhoto line_gamer={gamer}/></div>;

const TeamLeft = ({coef, gamer}) => <div className="team">
    <TeamName coef={coef} gamer={gamer}/>
    <TeamLogo gamer={gamer}/>
</div>;

const TeamRight = ({coef, gamer}) => <div className="team">
    <TeamLogo gamer={gamer}/>
    <TeamName coef={coef} gamer={gamer}/>
</div>;

const ACQUISITION_LANDING = 'ACQUISITION_LANDING';

export default class AdvertisingLanding extends PureComponent {
    placeBetClick = e => {
        e.preventDefault();
        logEvent('PROMO_LANDING_INTERACTION', {SOURCE: 'PLACE_A_BET_TAPPED'});
        openRegistration(ACQUISITION_LANDING);
    };

    handleAmplitudeEventIosTap = () => {
        logEvent('PROMO_LANDING_INTERACTION', {SOURCE: 'IOS_APP_TAPPED'});
    };

    handleAmplitudeEventAndroidTap = () => {
        logEvent('PROMO_LANDING_INTERACTION', {SOURCE: 'ANDROID_APP_TAPPED'});
    };

    render = () => <div className="advertising">
        <div className="advertising__inner">
            <div className="header">
                <div className="wrap wrap-wide wrap-wide--header">
                    {SiteVersion.logoLink && SiteVersion.logoLink(null, true)}
                    <div className="header__content">
                        <div className="header__controls">
                            <div className="header__control reg-button">
                                <SignUpButton usingOn="ACQUISITION_LANDING"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-block tap_bar-hide">
                <div className="wrap wrap-wide wrap-wide--content">
                    <div id="react-content" className="content">
                        <div className="advertising__content">
                            <div className="advertising__content__inner">
                                <Bonus/>
                                <div className="advertising__right">
                                    <div className="terms_conditions desktop--hidden">
                                        <a href={getLinkPath('/terms_and_conditions')}>
                                            {I18n.t('advertising.terms_conditions')}
                                        </a>
                                    </div>
                                    <div className="match__list">
                                        {window.SITE_DATA.PAGE_DATA.top_matches &&
                                        window.SITE_DATA.PAGE_DATA.top_matches.map(
                                            line => <div className="match" key={line.hash_id}>
                                                <div className="match-row">
                                                    <TeamLeft coef={line.coef_1} gamer={line.gamer1}/>
                                                    <div className="match-info">
                                                        <div className="match-game">
                                                            <div className="match-game-logo">
                                                                {gameImage(line.filter)}
                                                            </div>
                                                            <div className="match-game-date">
                                                                <span className="match-game-date-day">
                                                                    {date(line.date)}
                                                                </span>
                                                                <span className="match-game-date-time">
                                                                    {time(line.date)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="match-actions mobile--hidden">
                                                            <Link
                                                                onClick={this.placeBetClick}
                                                                to={linkLine(line.hash_id)}
                                                                className="btn btn--invert"
                                                            >
                                                                {I18n.t('place_a_bet_full')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <TeamRight coef={line.coef_2} gamer={line.gamer2}/>
                                                </div>
                                                <div className="match-actions mobile--show">
                                                    <Link
                                                        onClick={this.placeBetClick}
                                                        to={linkLine(line.hash_id)}
                                                        className="btn btn--invert"
                                                    >
                                                        {I18n.t('place_a_bet_full')}
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="advertising__footer">
                                <div className="advertising__footer__inner">
                                    <div className="advertising__footer--column desktop--show">
                                        <a href={getLinkPath('/terms_and_conditions')}>
                                            {I18n.t('advertising.terms_conditions')}
                                        </a>
                                    </div>
                                    <div className="advertising__footer--column advertising__footer--column--app">
                                        <a
                                            id="ios_app_download"
                                            onClick={this.handleAmplitudeEventIosTap}
                                            className="advertising__app-link"
                                            href={linkIOSDownload()}
                                        >
                                            <svg height="50px" width="149px">
                                                <use xlinkHref="#svg-app_store"/>
                                            </svg>
                                        </a>
                                        <Link
                                            id="android_app_download"
                                            onClick={this.handleAmplitudeEventAndroidTap}
                                            className="advertising__app-link"
                                            to={linkAndroid()}
                                        >
                                            <svg height="50px" width="150px">
                                                <use xlinkHref="#svg-google_play"/>
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className="advertising__footer--column">
                                        <div className="payment-system__list">
                                            <span className="payment-system">
                                                <svg height="20px" width="55px">
                                                    <use xlinkHref="#svg-visa"/>
                                                </svg>
                                            </span>
                                            <span className="payment-system">
                                                <svg height="36px" width="53px">
                                                    <use xlinkHref="#svg-master_card"/>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="license-list">
                                            <div id="license">
                                                <div
                                                    id="apg-seal-container"
                                                    data-apg-seal-id="e91736fd-7812-4361-9ecb-526f856f4d68"
                                                    data-apg-image-size="128"
                                                    data-apg-image-type="basic-small"
                                                />
                                            </div>

                                            <svg height="44px" width="45px">
                                                <use xlinkHref="#svg-eighteenplus-adv"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
