import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {flagsStore, userStore} from 'app/store/global';
import {Store} from 'app/store';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import SelectPicker from 'app/components/reusable/SelectPicker';
import {UNKNOWN_COUNTRY} from 'app/utils/Consts';

interface RowCountryCodeProps {
    rowClass: string;
    errorHint: ReactNode;
    cleanError: (field: string) => void;
    country_code: string;
}

export class RowCountryCode extends Component<RowCountryCodeProps> {
    state = {
        countryCode: this.props.country_code && this.props.country_code !== UNKNOWN_COUNTRY
            ? this.props.country_code
            : userStore.signUpCountry
    };

    availableFlags = (): CollectionInterface[] => flagsStore.flags.map((flag): CollectionInterface => ({
        text: flag.name,
        value: flag.code
    }));

    handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue = e.target.value;
        this.setState({countryCode: selectedValue});
    };

    cleanError = (): void => {
        this.props.cleanError('country_code');
    };

    render(): ReactNode {
        const {rowClass, errorHint} = this.props;
        const {countryCode} = this.state;
        return <div className={rowClass}>
            <label className="field-label" htmlFor="field-country">{I18n.t('country')}</label>
            <div className="field"
                onClick={this.cleanError}>
                {userStore.unknownCountryCode()
                    ? <div className="field__select classical-select">
                        <SelectPicker
                            onChange={this.handleOnChange}
                            name="country_code"
                            value={countryCode}
                            collection={this.availableFlags()}
                            className="chosen-select field__select-chosen"
                            placeholder={I18n.t('select_a_country')}
                            id="field-country"
                        />
                        <div className="field__select-arrow"/>
                    </div>
                    : <input className="field__input"
                        name="country_code"
                        defaultValue={Store.userCountry(countryCode)} disabled/>}
            </div>
            {errorHint}
        </div>;
    }
}
